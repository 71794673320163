import React, { useRef, useState } from 'react';
import Checkbox from "../../../../base/components/Checkbox";
import Icon from "../../../../base/components/Icon";
import { CustomDatePicker } from "../../../../base/components/Datepicker";
import joinClassNames from "../../../../base/helpers/joinClassNames";
import { MAX_RANGE_LENGTH } from "../../Biomarkers/CreateEdit/components/constants";
import { floatPositiveOnly } from "../../../../base/helpers/string";
import { FormikControlledInput } from "../../../../base/components/FormikInput";
import { Dropdown, DropdownMenu, DropdownToggle, Spinner } from "reactstrap";
import TableSearch from "../../../../base/components/Table/tableSearch";
import { useHighlight } from "../../../../base/hooks/useHighlight";
import { usePowerUnitFormat } from '../../../../base/hooks/usePowerUnitFormat';
import { useField } from "formik";
import { useTranslate } from "../../../../base/hooks/useTranslate";
import { deleteItemFromObject } from "../../../../base/helpers/object";



export const FormHeader = ({
  handleSelectAll,
  dateOnChange,
  date,
  isAllSelected,
  isAnySelected,
  onDelete
}) => {
  return (
    <section className="d-flex align-items-center justify-content-between upload-manually--header">
      <Checkbox
        text="Select all"
        value={isAllSelected}
        onChange={handleSelectAll}
        id="all"
      />

      <section className="d-flex align-items-center">
        <CustomDatePicker
          date={date}
          onChange={dateOnChange}
          className="me-4"
        />
        <Icon
          icon="trashIcon"
          className={joinClassNames(isAnySelected ? "cursor-pointer" : "cursor-default upload-manually__disabled-icon")}
          onClick={() => {
            if (!isAnySelected) return;
            onDelete()
          }}
        />
      </section>
    </section>
  )
}

const BiomarkerDropdownWithSearch = ({
  value = {},
  search,
  onSearch,
  loading,
  options,
  onSelect,
  toggleDropdown,
  isOpen,
  setTouched,
  isError,
  placeholder,
  valueClassName,
}) => {
  const dropdownRef = useRef();
  const { decorateText } = useHighlight(search);

  return (
    <section
      ref={dropdownRef}
      className={joinClassNames(
        "w-100 filter-dropdown rules-dropdown form-recommendations--select",
        isError && "is-invalid",
      )}
    >
      <Dropdown
        isOpen={isOpen}
        toggle={() => {
          if (isOpen) {
            setTouched(true)
          }
          toggleDropdown();
        }}
        className="d-inline-block"
      >
        <DropdownToggle
          className={joinClassNames("filter-toggle", isOpen && "with-border", isError && "border-error")}
          tag="section"
        >
          <section className={joinClassNames("rules-dropdown--value")}>
            <span
              className={joinClassNames("ms-2 me-1 text-truncate rules-dropdown--value__span", !value.name && "text-secondary", valueClassName)}
            >
              {value.name ?? placeholder ?? "Select biomarker"}
            </span>
            <section className="me-1 d-flex align-items-center">
              <i className={joinClassNames("mdi mdi-chevron-down", isOpen && "mdi-rotate-180")}/>
            </section>
          </section>
        </DropdownToggle>

        <DropdownMenu className="rules-menu pb-0 w-100">

          <section className="p-2 px-3">
            <TableSearch
              search={search}
              onSearch={onSearch}
              autoFocus
            />
          </section>

          {loading ?
            <section className="d-flex justify-content-center my-5 ">
              <Spinner color="info" className="mx-auto"/>
            </section>
            :
            <section className="rules-menu__options">
              {options.map((item, itemIndex) => {
                return (
                  <section key={item.id ?? itemIndex} className="rules-menu--option text-truncate" onClick={() => {
                    onSelect(item)
                    toggleDropdown();
                    setTouched(true);
                  }}>
                    <span {...decorateText(item.name)} />
                  </section>
                )
              })}
            </section>
          }

          {!options.length && !loading &&
            <section className="w-100 text-center my-5 text-secondary">
              <label className="mb-0">
                {search ? "No matches found" : "No biomarkers for now"}
              </label>
            </section>
          }
        </DropdownMenu>
      </Dropdown>
    </section>
  )
}

export const BiomarkerField = ({
  value,
  search,
  onSearch,
  loading,
  options,
  onSelect,
  toggleDropdown,
  isOpen,
  name,
  label,
  backendError,
  placeholder,
  sectionClassName,
  valueClassName,
}) => {

  const [field, { error, touched }, { setTouched }] = useField({ name });
  const [translate] = useTranslate();
  const isError = (error && touched) || backendError;

  return (
    <section className={sectionClassName}>
      <label>{label}</label>
      <BiomarkerDropdownWithSearch
        value={value}
        search={search}
        onSearch={onSearch}
        loading={loading}
        options={options}
        onSelect={onSelect}
        toggleDropdown={toggleDropdown}
        isOpen={isOpen}
        name={name}
        label={label}
        setTouched={setTouched}
        touched={touched}
        isError={isError}
        placeholder={placeholder}
        valueClassName={valueClassName}
      />
      <span className="invalid-feedback">{translate(error, { label })}</span>
    </section>
  )
}

export const UserResult = ({
  biomarker = {},
  unit,
  selected,
  setFieldValue,
  keyForResult,
  unitOptions,
  options,
  setFieldTouched,
  status = [],
  setStatus,
  searchInSelect,
  onUpdateSearchInSelect,
  isLoading
}) => {
  const [isOpen, updateIsOpen] = useState(false);

  const {powerFormat} = usePowerUnitFormat()

  const unitKey = `${keyForResult}.unit`
  const biomarkerKey = `${keyForResult}.biomarker`
  const valueKey = `${keyForResult}.value`

  return (
    <section className="upload-manually">
      <Checkbox
        text=""
        value={selected}
        onChange={() => setFieldValue(`${keyForResult}.selected`, !selected)}
        id={keyForResult}
        className="upload-manually__checkbox"
      />

      <BiomarkerField
        value={biomarker}
        search={searchInSelect}
        onSearch={(search) => onUpdateSearchInSelect(search)}
        loading={isLoading}
        options={options}
        onSelect={async (biomarker) => {
          await setFieldValue(biomarkerKey, biomarker)
          await setFieldValue(unitKey, biomarker.unit)
          setStatus(deleteItemFromObject(status, biomarker.id))
          setFieldTouched(biomarkerKey, true)
          setFieldTouched(unitKey, true)
          onUpdateSearchInSelect("")
        }}
        toggleDropdown={() => updateIsOpen(prevState => !prevState)}
        isOpen={isOpen}
        name={biomarkerKey}
        label="Biomarker"
        backendError={status[biomarker.id]}
        valueClassName="table-cell-25"
      />

      <FormikControlledInput
        placeholder="0"
        name={valueKey}
        maxLength={MAX_RANGE_LENGTH}
        label="Value"
        containerClassName="upload-manually__value"
        formatValue={floatPositiveOnly}
        onChange={(value) => setFieldValue(valueKey, value)}
        backendError={status[biomarker.id]}
      />

      <section className="d-flex flex-column justify-content-between">
        <label>Unit</label>
        <label className="mt-1 font-weight-normal">{powerFormat( unitOptions.find(({ id }) => id === unit?.id)?.value ) ?? "-"}</label>
      </section>
    </section>
  )
}
