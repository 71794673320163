import Http from "./Http";

class ProgramsService extends Http {
    static $displayName = "ProgramsService";

    async getPrograms(params) {
        return await this.get(`/programs`, { params });
    }

    async getProgramById(id) {
        return await this.get(`/programs/${id}`);
    }

    createProgram(values) {
        return this.post(`/programs`, values);
    }

    deleteProgram(id) {
       return this.delete(`/programs/${id}`);
    }

    getFoods() {
        return this.get("/dashboard/food-intolerance/foods");
    }
}

export default ProgramsService;
