export const PROGRAMS_GROUP_ROUTES = {
  BASE: "/programs/*",
  LIST: "/",
  CREATE_EDIT: `/create-edit`,
  DETAILS: `/details/:id`,
};

export const PROGRAMS_GROUP_LINKS = {
  BASE: "/app/programs",
  LIST: "/app/programs",
  CREATE_EDIT: `/app/programs/create-edit`,
  DETAILS: `/app/programs/details/:id`,
};