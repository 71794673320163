import React, { useState } from 'react';
import { UncontrolledTooltip } from "reactstrap";
import { useLocationQuery } from '../../../../base/hooks/useQueryString';
import { formatISODate } from '../../../../base/helpers/date';
import {
  ThreeDotDropdown,
  VIEW_HL7,
  MARK_AS_CANCELLED,
  MARK_AS_VERIFIED,
  REPROCESS_HL7,
  VIEW_CANCELLATION_REASON,
  THREE_DOT_TYPES,
  VIEW_RESULTS,
  DOWNLOAD_FILE,
  STATUS_IDS,
  MARK_AS_RECEIVED_BY_LAB,
  UPLOAD_CSV_FILE,
  VIEW_CSV_FILE,
  REPROCESS_CSV_FILE,
  BUTTONS_FOR_STATUSES
} from '../../CustomerResults/ResultsList/components';
import TableSearch from '../../../../base/components/Table/tableSearch';
import Icon from '../../../../base/components/Icon';
import joinClassNames from '../../../../base/helpers/joinClassNames';
import { CATEGORY_LABELS, CUSTOMER_RESULTS_CATEGORIES } from "../../CustomerResults/constants";

const Hl7ObjectStatuses = {
  1: 'new',
  2: 'inProgress',
  3: 'error',
  4: 'verified',
  5: 'canceled',
};

const BLOOD_CATEGORY = "Blood";
const DNA_CATEGORY = "DNA";
const FOOD_INTOLERANCE_CATEGORY = "Food Intolerance";

const CATEGORIES = {
  1: BLOOD_CATEGORY,
  2: DNA_CATEGORY,
  3: FOOD_INTOLERANCE_CATEGORY
}

const StatusLabel = ({ status }) => {
  return <div className={`lab-result-status-tag-${status}`}>{status}</div>;
};

export const columns = [
  {
    Header: '#',
    width: 50,
    className: 'first-sticky-column',
    headerClassName: 'first-sticky-column',
    Cell: ({ row: { index } }) => {
      const {
        params: { offset = 0 },
      } = useLocationQuery();
      return <label className="mb-0">{index + 1 + (offset ?? 0)}</label>;
    },
  },
  {
    Header: 'Product Name',
    accessor: 'testProductName',
    className: 'second-sticky-column',
    headerClassName: 'second-sticky-column',
    width: 200,
    canSort: true,
    Cell: ({ value }) => {
      return (
        <section className="w-fit-content d-flex align-items-center">
          {value}
        </section>
      );
    },
  },
  {
    Header: 'Order ID',
    accessor: 'orderId',
    canSort: true,
    minWidth: 120,
    Cell: ({ value }) => {
      return (
        <section className="d-flex justify-content-between align-items-center">
          {value}
        </section>
      );
    },
  },
  {
    Header: 'Category',
    accessor: 'category',
    width: 180,
    Cell: ({ value, row: { original } }) => {
      // -- if sampleCode is null -> claim this result as manually uploaded PDF result --
      if (!original?.sampleCode) {
        value = CUSTOMER_RESULTS_CATEGORIES.BLOOD_CATEGORY;
      }
      return <div className="w-100 text-truncate">{CATEGORY_LABELS[value]}</div>;
    },
  },
  {
    Header: 'Sample ID',
    accessor: "sampleCode",
    minWidth: 160,
    canSort: true,
    Cell: ({ value }) => <section className="d-flex justify-content-between align-items-center">{value}</section>,
  },
  {
    Header: 'Activ. date',
    accessor: "activatedAt",
    minWidth: 160,
    canSort: true,
    Cell: ({ value }) => <section className="d-flex justify-content-between align-items-center">{formatISODate(value)}</section>,
  },
  {
    Header: 'Sample date',
    accessor: "sampleAt",
    minWidth: 160,
    canSort: true,
    Cell: ({ value }) => <section className="d-flex justify-content-between align-items-center">{formatISODate(value)}</section>,
  },
  {
    Header: 'Lab rec. date',
    accessor: "labReceivedAt",
    minWidth: 160,
    canSort: true,
    Cell: ({ value }) => <section className="d-flex justify-content-between align-items-center">{formatISODate(value)}</section>,
  },
  {
    Header: 'Result date',
    accessor: "resultAt",
    minWidth: 130,
    canSort: true,
    Cell: ({ value }) => <section className="d-flex justify-content-between align-items-center">{formatISODate(value)}</section>,
  },
  {
    Header: 'Test ID',
    accessor: "id",
    minWidth: 110,
    canSort: true,
    Cell: ({ value }) => <section className="d-flex justify-content-between align-items-center">{value}</section>,
  },
  {
    Header: 'Lab name',
    accessor: "lab",
    minWidth: 130,
    canSort: true,
    Cell: ({ value }) => <section className="d-flex justify-content-between align-items-center">{value}</section>,
  },
  {
    Header: 'Lab ID',
    accessor: "labId",
    minWidth: 100,
    canSort: true,
    Cell: ({ value }) => <section className="d-flex justify-content-between align-items-center">{value}</section>,
  },
  {
    Header: 'Status',
    className: 'before-last-sticky-column',
    accessor: 'status',
    width: 100,
    canSort: true,
    Cell: ({
      value,
      row: {
        original: { isCriticalResult, toFollow, id },
      },
    }) => {
      const isErrorStatus = value === STATUS_IDS.error;

      return (
        <div className="d-flex align-items-center">
          <StatusLabel status={Hl7ObjectStatuses[value]} />
          {isCriticalResult && !isErrorStatus && <Icon icon="isCriticalResult" className="ms-2" />}
          {isErrorStatus && !!toFollow &&
            <>
              <Icon icon="infoCircle" className="ms-2" id={`resultErrorMessage-${id}`}/>
              <UncontrolledTooltip
                popperClassName={joinClassNames(
                  "tooltip-alternative-name error-result-tooltip",
                )}
                innerClassName="pre-line text-truncate error-inner-max-height"
                placement="bottom"
                target={`resultErrorMessage-${id}`}
              >
                {toFollow}
              </UncontrolledTooltip>
            </>
          }
        </div>
      );
    },
  },
  {
    Header: () => <div className="">Actions</div>,
    className: 'last-sticky-column',
    accessor: 'actions',
    width: 75,
    Cell: ({
      row: {
        original: { id, status, category, ...rest },
      },
      actions,
    }) => {
      const [isOpen, updateIsOpen] = useState(false);
      const { markAsCancelled, markAsVerified, viewFiles, viewResults, reprocessHL7, viewCancellationReason, downloadFile, markAsReceivedByLab, uploadCSVFile, viewCSVFile, reprocessCSVFile } = actions;

      const optionsPerStatus = {
        [1]: [
          VIEW_HL7,
          MARK_AS_CANCELLED
        ],
        [2]: [
          VIEW_HL7,
        ],
        [4]: [
          VIEW_HL7,
          VIEW_RESULTS,
          { ...DOWNLOAD_FILE, disabled: rest?.pdfResultFile?.link === undefined}
        ],
        [5]: [
          VIEW_HL7,
          VIEW_CANCELLATION_REASON
        ],
        [3]: [
          VIEW_HL7,
          MARK_AS_CANCELLED,
          MARK_AS_VERIFIED,
          REPROCESS_HL7
        ]
      }

      const BUTTONS_FOR_STATUSES_BY_FOOD = [
        [],
        [MARK_AS_RECEIVED_BY_LAB, MARK_AS_CANCELLED],
        [UPLOAD_CSV_FILE, MARK_AS_CANCELLED],
        [VIEW_CSV_FILE, MARK_AS_CANCELLED, MARK_AS_VERIFIED, REPROCESS_CSV_FILE],
        [VIEW_CSV_FILE, VIEW_RESULTS, DOWNLOAD_FILE],
        [VIEW_CSV_FILE, VIEW_CANCELLATION_REASON],
      ]

      const menuActions = {
        [THREE_DOT_TYPES.view]: () => viewFiles({ id, status, ...rest }),
        [THREE_DOT_TYPES.markAsCancelled]: () => markAsCancelled(id),
        [THREE_DOT_TYPES.markAsVerified]: () => markAsVerified(id),
        [THREE_DOT_TYPES.reprocess]: () => reprocessHL7(id),
        [THREE_DOT_TYPES.viewResults]: () => viewResults({ id, status, category, ...rest }),
        [THREE_DOT_TYPES.viewCancellationReason]: () => viewCancellationReason(rest.cancellationReason),
        [THREE_DOT_TYPES.markAsReceivedByLab]: () => markAsReceivedByLab(id),
        [THREE_DOT_TYPES.uploadCSVFile]: () => uploadCSVFile({ id, status, ...rest }),
        [THREE_DOT_TYPES.viewCSVFile]: () => viewCSVFile({ status, ...rest, id }),
        [THREE_DOT_TYPES.reprocessCSVFile]: () => reprocessCSVFile({ id, status, ...rest }),
        [THREE_DOT_TYPES.downloadFile]: () => downloadFile(rest?.pdfResultFile?.link)
      };

      let menuOptions = [];

      // -- options for Food Intolerance --
      if (CATEGORIES[category] === FOOD_INTOLERANCE_CATEGORY) {
        menuOptions = BUTTONS_FOR_STATUSES_BY_FOOD[status];
      }

      // -- options for Manually uploaded PDF Results --
      if (!rest?.sampleCode) {
        menuOptions = [
          VIEW_RESULTS,
          { ...DOWNLOAD_FILE, disabled: rest?.pdfResultFile?.link === undefined}
        ];
      }

      // --  options for HL7 Results --
      if (!menuOptions.length) {
        menuOptions = BUTTONS_FOR_STATUSES[status];
      }


      return (
        <section className={joinClassNames('d-flex align-items-center justify-content-center text-primary', isOpen && 'force-z-index')}>
          <ThreeDotDropdown
            id={id}
            isOpen={isOpen}
            updateIsOpen={updateIsOpen}
            options={menuOptions}
            onSelect={type => {
              menuActions[type]();
            }}
          />
        </section>
      );
    },
  },
];

export const SearchPlaceholder = () => (
  <>
    No lab results found.
  </>
);

export const TableHeader = ({ searchProvider }) => {
  return (
    <section className="d-flex flex-column">
      <section className="d-flex w-100 mb-3 align-items-center justify-content-between">
        <label className="font-weight-semibold mb-1">Lab Test Results</label>
        <TableSearch
          className="biomarkers-search me-3"
          search={searchProvider.getValue()}
          onSearch={searchProvider.setValue}
          placeholder="Search product name, order ID or sample ID"
        />
      </section>
    </section>
  );
};
