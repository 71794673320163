import React, { useCallback, useRef, useState } from "react";
import { CustomModal, ModalActions, ModalBody, ModalHeader } from "../../../../base/components/CustomModal";
import joinClassNames from "../../../../base/helpers/joinClassNames";
import Button from "../../../../base/components/Button";
import { BUTTON_COLORS } from "../../../../base/components/Button/appearance";
import {
    DropZoneCard
} from "../../../../base/components/Dropzone";
import { useService } from "../../../../base/hooks/useService";
import ToasterService from "../../../../services/ToastService";
import {
    validateEmptyFile,
    validateFileSize, validateFileType
} from "../../../../validation/fileUploadAndProcessing";
import { Spinner } from "reactstrap";
import Icon from "../../../../base/components/Icon";
import {
    ALLOWED_DNA_CSV_FILE_EXTENSION, BYTES_IN_KILOBYTE,
    ERROR_ALLOWED_DNA_CSV_FILE_TYPE_MESSAGE, ERROR_DNA_CSV_FILE_SIZE_MESSAGE,
    MAX_DNA_CSV_FILE_SIZE, REACT_DROPZONE_INVALID_FILE_TYPE_ERROR
} from "../../../../base/constants/shared";
import UserResultsService from "../../../../services/UserResults";
import S3Service from "../../../../services/S3Service";
import { noop } from "lodash";

const FileUpload = ({
                        isOpen,
                        updateIsOpen,
                        submitBtnText,
                        title,
                        description,
                        className = "",
                        fileMask,
                        userId,
                        testObjectId,
                        afterUpload = noop
                    }) => {
        /**
         * @type {ToasterService}
         */
        const toastService = useService(ToasterService);
        /**
         * @type {UserResultsService}
         */
        const userResultsService = useService(UserResultsService);

        /**
         * @type {S3Service}
         */
        const s3Service = useService(S3Service);

        const [errorMessage, setErrorMessage] = useState("");
        const [isLoading, setIsLoading] = useState(false);
        const [selectedFile, setSelectedFile] = useState(null);
        const [selectedFileName, setSelectedFileName] = useState("");
        const [isUploading, setIsUploading] = useState(false);

        const abortSignalRef = useRef(undefined);

        const handleAcceptedFile = useCallback(async (file) => {
            setErrorMessage("");
            setIsLoading(true);
            setSelectedFileName(file.name);

            try {
                validateFileType(file, ALLOWED_DNA_CSV_FILE_EXTENSION, ERROR_ALLOWED_DNA_CSV_FILE_TYPE_MESSAGE);
                validateFileSize(file, MAX_DNA_CSV_FILE_SIZE, ERROR_DNA_CSV_FILE_SIZE_MESSAGE);
                validateEmptyFile(file);

            } catch ({ message }) {
                toastService.error(message);
                setErrorMessage(message);
                setIsLoading(false);
                return;
            }

            setSelectedFile(file);
            setIsLoading(false);

        }, [setErrorMessage]);


        const onDrop = useCallback(
            (acceptedFiles, fileRejections) => {

                fileRejections.forEach((file) => {
                    file.errors.forEach((err) => {
                        if (err.code === REACT_DROPZONE_INVALID_FILE_TYPE_ERROR) {
                            toastService.error(ERROR_ALLOWED_DNA_CSV_FILE_TYPE_MESSAGE);
                        }
                    });
                });


                if (!acceptedFiles.length) return;

                acceptedFiles.forEach((file) => handleAcceptedFile(file));

            },
            [handleAcceptedFile]
        );


        const afterSuccess = (result) => {
            if (result?.totalRecords === 0) {
                toastService.error("File was uploaded with errors");
            } else {
                toastService.success("File was successfully uploaded");
            }

            setIsLoading(false);
        };

        const onSubmit = () => {
            const controller = new AbortController();
            const abortSignal = controller.signal;

            abortSignalRef.current = controller;

            setIsUploading(true);
            s3Service.uploadFoodCsv(selectedFile, abortSignal)
                .then(response => {
                    const params = { fileId: response.file.id };
                    if (userId) {
                        params.userId = userId;
                    }
                    if (testObjectId) {
                        params.id = testObjectId;
                    }
                    return userResultsService.processFoodData(params);
                })
                .then(afterSuccess)
                .finally(() => {
                    setIsUploading(false);
                    afterUpload();
                    onClose();
                });

        };

        const onLoadingCancel = () => {
            setIsLoading(false);
            setSelectedFile(null);
            setErrorMessage("");
            setSelectedFileName("");
            updateIsOpen(false);
        };

        const onFileDelete = () => {
            setSelectedFile(null);
            setSelectedFileName("");
        };

        const onClose = () => {
            abortSignalRef.current?.abort("Upload cancelled");
            updateIsOpen(false);
        };

        return (
            <CustomModal isOpen={isOpen} className="filter-modal__upload-file">
                <ModalHeader onClose={onClose}>
                    {title}
                </ModalHeader>
                <ModalBody>
                    <div className={joinClassNames("", className)}>
                        {(isLoading || isUploading) &&
                            <section className="filter-modal__upload-file__loading-file">
                                <Spinner color="primary"/>
                                <p className="my-4">{selectedFileName}</p>
                                {!isUploading &&
                                    <button
                                        className="filter-modal__upload-file__loading-file__cancel-button"
                                        onClick={onLoadingCancel}
                                    >Cancel
                                    </button>
                                }
                            </section>
                        }
                        {!selectedFile && !isLoading &&
                            <DropZoneCard
                                onDrop={onDrop}
                                errorMessage={errorMessage}
                                isDropContainer={true}
                                className={className}
                                fileMask={fileMask}
                            >
                                <section className="upload-container--section">
                                    <p className="upload-container--section__cloud-upload-desc">{description}</p>
                                </section>
                            </DropZoneCard>
                        }
                        {selectedFile && !isLoading && !isUploading &&
                            <div>
                                <div
                                    className="d-flex align-items-center justify-content-between p-4 selected-file-container">
                                    <div className="d-flex align-items-center">
                                        <Icon icon="fileIcon" className="me-4 cursor-pointer"/>
                                        <div className="d-flex flex-column justify-content-between">
                                            <span
                                                className="selected-file-container__selected-file-name mb-1">{selectedFile.name}</span>
                                            <span
                                                className="font-semibold">{(selectedFile.size / BYTES_IN_KILOBYTE).toFixed(2)} KB</span>
                                        </div>
                                    </div>
                                    <Icon
                                        icon="trashIcon"
                                        className="mdi-cursor-pointer"
                                        onClick={onFileDelete}
                                    />
                                </div>
                            </div>
                        }
                    </div>
                </ModalBody>
                {selectedFile && !isLoading && <ModalActions>
                    <Button color={BUTTON_COLORS.primaryOutline} onClick={onClose} className="mb-0">
                        Cancel
                    </Button>
                    <Button disabled={isLoading} color={BUTTON_COLORS.primary} onClick={() => {
                        onSubmit();
                    }} className="mb-0">
                        {submitBtnText}
                    </Button>
                </ModalActions>}
            </CustomModal>
        );
    }
;

export default FileUpload;
