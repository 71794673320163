import VerticalLayout from "../../base/components/VerticalLayout";
import { Route, Routes, Navigate } from "react-router-dom";
import React, { useMemo } from "react";
import ErrorBoundary from "../../base/components/ErrorBoundary";
import Biomarkers from "./Biomarkers";
import FoodIntolerance from "./FoodIntolerance"
import Dashboard from "./Dashboard";
import Recommendations from "./Recommendations";
import { APP_GROUP_LINKS, APP_GROUP_ROUTES } from "./config";
import { BIOMARKERS_GROUP_ROUTES } from "./Biomarkers/config";
import CustomerManagement from "./CustomerManagement";
import CustomerResults from "./CustomerResults";
import ContentPlayground from "./ContentPlayground";
import FoodAndDiet from "./FoodAndDiet";
import Programs from "./Programs";

const AppRoutes = () => {
  const schema = useMemo(() => [
    {
      label: "SIDEBAR.DASHBOARD",
      linkTo: "/app/dashboard",
      icon: "dashboard"
    },
    {
      label: "SIDEBAR.BIOMARKERS",
      linkTo: "/app/biomarkers",
      icon: "biomarkers"
    },
    {
      label: "SIDEBAR.FOOD_INTOLERANCE",
      linkTo: "/app/food-intolerance",
      icon: "foodIntolerance"
    },
    {
      label: "SIDEBAR.RECOMMENDATIONS",
      linkTo: "/app/recommendations",
      icon: "recommendations"
    },
    {
      label: "SIDEBAR.CUSTOMER_MANAGEMENT",
      linkTo: "/app/customer-management",
      icon: "user"
    },
    // {
    //   label: "SIDEBAR.PROGRAMS",
    //   linkTo: "/app/program",
    //   icon: "program",
    //   bottomLink: true,
    // },
    // {
    //   label: "SIDEBAR.CONTENT",
    //   linkTo: "/app/content",
    //   icon: "content",
    //   bottomLink: true,
    // },
    // {
    //   label: "SIDEBAR.FOOD_AND_RECEIPTS",
    //   linkTo: "/app/food-and-diet",
    //   icon: "foodAndReceipts",
    //   bottomLink: true,
    // },
  ], []);

  return (
    <VerticalLayout schema={schema}>
      <ErrorBoundary>
        <Routes>
          <Route path={APP_GROUP_ROUTES.DASHBOARD} element={<Dashboard/>}/>
          <Route path={BIOMARKERS_GROUP_ROUTES.BASE} element={<Biomarkers/>}/>
          <Route path={APP_GROUP_ROUTES.FOOD_INTOLERANCE} element={<FoodIntolerance/>}/>
          <Route path={APP_GROUP_ROUTES.RECOMMENDATIONS} element={<Recommendations/>}/>
          <Route path={APP_GROUP_ROUTES.CUSTOMER_MANAGEMENT} element={<CustomerManagement/>}/>
          <Route path={APP_GROUP_ROUTES.CUSTOMER_RESULTS} element={<CustomerResults/>}/>
          <Route path={APP_GROUP_ROUTES.FOOD_AND_DIET} element={<FoodAndDiet/>}/>
          <Route path={APP_GROUP_ROUTES.CONTENT_PLAYGROUND} element={<ContentPlayground />}/>
          <Route path={APP_GROUP_ROUTES.PROGRAMS} element={<Programs />}/>
          <Route path="/" element={<Navigate to={APP_GROUP_LINKS.DASHBOARD}/>}/>
        </Routes>
      </ErrorBoundary>
    </VerticalLayout>
  );
};

export default AppRoutes;
