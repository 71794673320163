import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
    CustomModal,
    ModalBody,
    ModalHeader
} from "../../../../base/components/CustomModal";
import Table from "../../../../base/components/Table";
import { useService } from "../../../../base/hooks/useService";
import UserResultsService from "../../../../services/UserResults";
import { useLoading } from "../../../../base/hooks/useLoading";
import { useLocation } from "react-router-dom";
import TableSearch from "../../../../base/components/Table/tableSearch";

import ValueWithLabel from "../../CustomerManagement/Details/ValueWIthLabel";
import Icon from "../../../../base/components/Icon";
import joinClassNames from "../../../../base/helpers/joinClassNames";
import { formatISODate } from "../../../../base/helpers/date";
import { useLocationSource, useSearchProvider, useSortProvider } from "../../../../base/components/Table/hooks";
import { useLocationQuery, useQueryString } from "../../../../base/hooks/useQueryString";
import { capitalize, chain } from "lodash";

const formatDate = (s = '') => s.split('T')[0].split('-').reverse().join('/');

const FOOD_KEY = {
    INTERPRETATION: "INTERPRETATION",
    FOOD: 'FOOD (English)',
    FOOD_GROUP: 'GROUP (English)',
    RESULT: "RESULT"
};

const FOOD_RANGE_ICON = {
    Elevated: "biomarkerRedAlert",
    Normal: "biomarkerGreenStatus",
    Borderline: "biomarkerYellowWarning"
};

export default function ResultsPopupFoodIntolerance({
                                                        isOpen,
                                                        updateIsOpen,
                                                        afterOnClose,
                                                        row
                                                    }) {
    /**
     * @type {UserResultsService}
     */
    const userResultsService = useService(UserResultsService);

    const { id, testProductName, labReceivedAt } = row;

    const [foods, setFoods] = useState([]);

    const [isLoading, { registerPromise }] = useLoading(false);
    const locationSource = useLocationSource();
    const { search: locationSearch } = useLocation();

    const {
        params: {
            rangeSearch,
            foodName,
            foodGroup
        }
    } = useQueryString(locationSearch);

    const searchProvider = useSearchProvider({
        source: locationSource,
        scope: "",
        alias: 'rangeSearch',
        onApplyClearScope: ["rangeSearch"]
    });

    const hasPdf = useMemo(() => row?.pdfResultFile?.link, [row]);

    const generateSortProviderParams = (name) => {
        const allParams = [
            "foodName",
            "foodGroup"
        ];
        return {
            source: locationSource,
            alias: name,
            scope: "",
            onApplyClearScope: allParams.filter(paramName => paramName !== name)
        };
    };

    const nameSortProvider = useSortProvider(generateSortProviderParams("foodName"));
    const foodGroupSortProvider = useSortProvider(generateSortProviderParams("foodGroup"));

    const onClose = () => {
        updateIsOpen(false);
        afterOnClose();
    };

    const getData = useCallback(() => {
        registerPromise(userResultsService.getCsvData(id))
            .then((data) => {
                setFoods(data.foodsData);
            });
    }, [id]);

    const mappedFoods = useMemo(() => {
        const filteredFood = chain(foods)
            .filter(item => {
                return (
                    (!rangeSearch ||
                        item[FOOD_KEY.FOOD].toLowerCase().includes(rangeSearch.toLowerCase()) ||
                        item[FOOD_KEY.FOOD_GROUP].toLowerCase().includes(rangeSearch.toLowerCase())
                    )
                );
            });

        if (foodName || foodGroup) {
            return filteredFood
                .orderBy(
                    foodName ? FOOD_KEY.FOOD : FOOD_KEY.FOOD_GROUP,
                    foodName ? foodName : foodGroup
                ).value();
        }

        return filteredFood.value();
    }, [foods, rangeSearch, foodName, foodGroup]);

    const downloadFile = useCallback(
        () => {
            if (hasPdf) {
                window.open(hasPdf, '_blank').focus();
            }
        },
        [hasPdf]
    );

    useEffect(() => {
        if (isOpen === true) {
            getData();
        }

        return () => {
            searchProvider.setValue();
            nameSortProvider.setValue();
            foodGroupSortProvider.setValue();
        };
    }, [isOpen]);

    return (
        <CustomModal scrollable={false} isOpen={isOpen} className="csvViewerModal" toggle={onClose}>
            <ModalHeader
                onClose={onClose}
                sectionClassName="border-none"
            >
                <p className="text-dark mb-1">{`${row.firstName} ${row.lastName}`}</p>
            </ModalHeader>
            <ModalBody className="h-82 d-flex flex-column ">
                <section className="d-flex align-items-center mb-3">
                    <ValueWithLabel
                        value={testProductName || "-"}
                        label="Product name"
                        className="me-4"
                    />
                    <ValueWithLabel
                        value={formatISODate(labReceivedAt)}
                        label="Test date"
                    />
                    <div className="ms-auto w-50 d-flex align-items-center">
                        <TableSearch
                            className="biomarkers-search me-3"
                            search={searchProvider.getValue()}
                            onSearch={searchProvider.setValue}
                            placeholder="Search food"
                        />
                        <Icon className={joinClassNames(hasPdf && 'cursor-pointer', !hasPdf && 'opacity-30')}
                              onClick={hasPdf ? downloadFile : undefined} icon="download"/>
                    </div>
                </section>

                <Table
                    columns={[
                        {
                            Header: "#",
                            width: 45,
                            className: 'text-center align-middle',
                            Cell: (cellProps) => {
                                const { params: { offset = 0 } } = useLocationQuery();
                                return <label className="mb-0">{cellProps.row.index + 1 + (offset ?? 0)}</label>;
                            }
                        },
                        {
                            Header: 'Food',
                            accessor: FOOD_KEY.FOOD,
                            canSort: true,
                            Cell: ({ value, row }) => {
                                return (
                                    <section className="d-flex align-items-center ">
                                        <Icon
                                            icon={FOOD_RANGE_ICON[row.original?.[FOOD_KEY.INTERPRETATION]] || FOOD_RANGE_ICON.Elevated} className={""}
                                        />
                                        <div className="ms-3 table-cell-25 text-truncate">{value}</div>
                                    </section>
                                );
                            }
                        },
                        {
                            Header: 'Group',
                            accessor: FOOD_KEY.FOOD_GROUP,
                            canSort: true,
                            Cell: ({ value, row }) => {
                                return (
                                    <section className="d-flex align-items-center ">
                                        <div className="ms-3 table-cell-25 text-truncate">{capitalize(value)}</div>
                                    </section>
                                );
                            }
                        },
                        {
                            Header: 'Result',
                            accessor: FOOD_KEY.RESULT
                        },
                        {
                            Header: 'Interpretation',
                            accessor: FOOD_KEY.INTERPRETATION
                        }
                    ]}
                    data={mappedFoods}
                    sortProviders={{
                        [FOOD_KEY.FOOD]: nameSortProvider,
                        [FOOD_KEY.FOOD_GROUP]: foodGroupSortProvider
                    }}
                    loading={isLoading}
                    commonPlaceholder="No results for now."
                    placeholderForSearch={"No results for now."}
                    withoutPagination={true}
                    headerClassName="position-sticky top-0"
                    tableClassName="custom-scrollbar"
                />

            </ModalBody>
        </CustomModal>
    );
}
