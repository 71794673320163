import { allRangesKeys, DNAm_CATEGORY_ID, DNAm_CATEGORY_NAME, RECOMMENDATION_TYPES_FOR_OUTPUT } from "../constants";
import { checkOnDefined } from "../fromValidation";
import { BULLET_LIST_TYPES_MAP, GROUP_ITEM_TYPES_ARRAY, GROUP_ITEM_TYPES_MAP } from "../components/constants";
import {
  CAUSES_SKIN_TABS,
  HIGH_TAB,
  LOW_TAB,
  REC_SECTION_IDS,
  SKIN_RANGES_VIEW_SECTIONS
} from "../Recommendations/constants";
import { generateGroupTab } from "../components/ResultSummaryTabs";

const prepareFilter = (currentFilters, allFilters, alias) => {
  return currentFilters.map(({ [alias]: currentValue }) => {
    return allFilters.find(({ value }) => value === currentValue);
  })
}

export const prepareRecommendations = (filterRecommendations) => {
  const recommendations = {};
  filterRecommendations.forEach(({ order, type, recommendation }) => {
    const keyForRecommendations = RECOMMENDATION_TYPES_FOR_OUTPUT[type];
    recommendations[keyForRecommendations] = [...(recommendations[keyForRecommendations] ?? []), {
      ...recommendation,
      order
    }];
    recommendation[keyForRecommendations]?.sort((a, b) => a.order < b.order);
  });
  return recommendations
}

export const transformGroupsModel = (groups = [], category, categoryId) => {
  if (category === DNAm_CATEGORY_NAME || categoryId === DNAm_CATEGORY_ID) {
    return {
      low: [],
      high: [REC_SECTION_IDS.high, REC_SECTION_IDS.criticalHigh]
    }
  }
  const transformedGroups = {
    low: [],
    high: []
  }

  groups.forEach(({ type, recommendationType }) => {
    transformedGroups[GROUP_ITEM_TYPES_ARRAY[type]]?.push(recommendationType);
  })

  return transformedGroups
}

const prepareBulletList = (bulletList = []) => {
  return bulletList.map(({ studyLinks = [], ...rest }) => {
    return {
      ...rest,
      studyLinks: studyLinks.map(({ content }) => content),
    }
  })
}

export const prepareBiomarkerRuleModel = ({ filters = [], ...otherValues }, allFiltersMap) => {
  const { allSexTypes, allAgeTypes, allEthnicityTypes, allOtherFeatureTypes } = allFiltersMap;
  return {
    ...otherValues,
    filters: filters.map(({
      sexes = [],
      ages = [],
      ethnicities = [],
      otherFeatures = [],
      filterRecommendations = [],
      groups = [],
      whatAreTheCauses = {},
      resultSummary = {},
      whatAreTheRisks = {},
      ...otherFilterValues
    }) => {
      return {
        ...otherFilterValues,
        sexes: prepareFilter(sexes, allSexTypes, "sex"),
        ages: prepareFilter(ages, allAgeTypes, "age"),
        ethnicities: prepareFilter(ethnicities, allEthnicityTypes, "ethnicity"),
        otherFeatures: prepareFilter(otherFeatures, allOtherFeatureTypes, "otherFeature"),
        recommendations: prepareRecommendations(filterRecommendations),
        groups: transformGroupsModel(groups, otherValues.category, otherValues.categoryId),
        resultSummary: Object.fromEntries(Object.entries(resultSummary).filter(([_, v]) => !!v)),
        whatAreTheCauses: { ...whatAreTheCauses, bulletList: prepareBulletList(whatAreTheCauses.bulletList) },
        whatAreTheRisks: { ...whatAreTheRisks, bulletList: prepareBulletList(whatAreTheRisks.bulletList) }
      }
    }),
  }
}

export const prepareBiomarkerCommonInfo = ({
  unit = {},
  category = {},
  alternativeNames = [],
  categoryId,
  id,
  createdAt,
  isDeleted,
  type,
  unitId,
  updatedAt,
  ...otherValues
}) => {
  return {
    unit: unit.unit,
    category: category.name,
    alternativeNames: alternativeNames.reduce((prevState, { id, name }) => ({ ...prevState, [id]: name }), {}),
    selectedFilterIndex: 0,
    ...otherValues,
  }
}

export const prepareBiomarkerModel = (biomarker, allFiltersMap) => {
  return prepareBiomarkerRuleModel(prepareBiomarkerCommonInfo(biomarker), allFiltersMap)
}

export const prepareRecommendationsModel = (recommendations = {}) => {
  const {
    criticalLow = [],
    low = [],
    subOptimal = [],
    optimal = [],
    supraOptimal = [],
    high = [],
    criticalHigh = []
  } = recommendations;

  return [
    ...criticalLow.map(({ id }, index) => ({ recommendationId: id, order: index + 1, type: 1 })),
    ...low.map(({ id }, index) => ({ recommendationId: id, order: index + 1, type: 2 })),
    ...subOptimal.map(({ id }, index) => ({ recommendationId: id, order: index + 1, type: 3 })),
    ...optimal.map(({ id }, index) => ({ recommendationId: id, order: index + 1, type: 4 })),
    ...supraOptimal.map(({ id }, index) => ({ recommendationId: id, order: index + 1, type: 5 })),
    ...high.map(({ id }, index) => ({ recommendationId: id, order: index + 1, type: 6 })),
    ...criticalHigh.map(({ id }, index) => ({ recommendationId: id, order: index + 1, type: 7 })),
  ].filter(({ recommendationId }) => !!recommendationId);
}

export const prepareGroupsModel = ({ low = [], high = [] } = {}) => {
  return [
    { type: GROUP_ITEM_TYPES_MAP.low, recommendationTypes: low },
    { type: GROUP_ITEM_TYPES_MAP.high, recommendationTypes: high }
  ].filter(({ recommendationTypes }) => recommendationTypes.length)
}

const prepareResultSummaryModel = ({ id, createdAt, updatedAt, ...rest } = {}, { biomarkerId, category }) => {
  const v = Object.fromEntries(Object.entries(rest).filter(([_, v]) => !!v));
  if (biomarkerId !== undefined && category  === DNAm_CATEGORY_ID) {
    if ("criticalHigh" in rest && rest?.high === undefined) {
      v.high = rest.criticalHigh
    }
    if ("high" in rest && rest?.criticalHigh === undefined) {
      v.criticalHigh = rest.high
    }
  }
  return v
}

const prepareBulletListModel = ({ bulletList = [], low, high } = {}, filterValues = {}, isSkinCategory) => {
  const lowTabsArray = isSkinCategory ? CAUSES_SKIN_TABS[0] : LOW_TAB;
  const isLowAllowed = lowTabsArray.rangesToApply.some(range => checkOnDefined(filterValues[range]))
  const isHighAllowed = HIGH_TAB.rangesToApply.some(range => checkOnDefined(filterValues[range]))

  const newList = bulletList.filter(({ type, content }) => {
    return !!content && (isLowAllowed && type === BULLET_LIST_TYPES_MAP.low) ||
      (isHighAllowed && type === BULLET_LIST_TYPES_MAP.high)
  })

  return {
    low: isLowAllowed ? low : undefined,
    high: isHighAllowed ? high : undefined,
    bulletList: newList.map(({ createdAt, updatedAt, filterId, id, category, studyLinks = [], ...rest }) => {
      return {
        ...rest,
        studyLinks: studyLinks.filter(value => !!value)
      }
    })
  };
}

export const prepareFilterValues = ({ filters, ...otherValues }, { isSkinCategory, isDNACategory, category }) => {
  return {
    ...otherValues,
    filters: filters.map(({
      createdAt,
      updatedAt,
      biomarkerId,
      ages,
      ethnicities,
      otherFeatures,
      sexes,
      recommendations,
      interactions = [],
      groups,
      resultSummary,
      whatAreTheRisks,
      whatAreTheCauses,
      criticalLowPercentage,
      lowPercentage,
      subOptimalPercentage,
      optimalPercentage,
      supraOptimalPercentage,
      highPercentage,
      criticalHighPercentage,
      ...otherFilterValues
    }) => {
      let rangeValues = {};

      allRangesKeys.forEach((key) => {
        rangeValues[key] = checkOnDefined(otherFilterValues[key]) ? Number(otherFilterValues[key]) : undefined
      })

      const vals = {
        ...otherFilterValues,
        ...rangeValues,
        sexes: sexes?.map(({ value }) => value),
        ages: ages?.map(({ value }) => value),
        ethnicities: ethnicities?.map(({ value }) => value),
        otherFeatures: otherFeatures?.map(({ value }) => value),
        recommendations: prepareRecommendationsModel(recommendations),
        interactions: interactions?.map(({ id, createdAt, updatedAt, filterId, ...interaction }) => interaction),
        groups: prepareGroupsModel(groups),
        resultSummary: prepareResultSummaryModel(resultSummary, { biomarkerId, category }),
        whatAreTheRisks: prepareBulletListModel(whatAreTheRisks, otherFilterValues),
        whatAreTheCauses: prepareBulletListModel(whatAreTheCauses, otherFilterValues, isSkinCategory),
      }

      if (isDNACategory === false) {
        vals.criticalLowPercentage = criticalLowPercentage ? Number.parseFloat(criticalLowPercentage) : criticalLowPercentage;
        vals.lowPercentage = lowPercentage ? Number.parseFloat(lowPercentage) : lowPercentage;
        vals.subOptimalPercentage = subOptimalPercentage ? Number.parseFloat(subOptimalPercentage) : subOptimalPercentage;
        vals.optimalPercentage = optimalPercentage ? Number.parseFloat(optimalPercentage) : optimalPercentage;
        vals.supraOptimalPercentage = supraOptimalPercentage ? Number.parseFloat(supraOptimalPercentage) : supraOptimalPercentage;
        vals.highPercentage = highPercentage ? Number.parseFloat(highPercentage) : highPercentage;
        vals.criticalHighPercentage = criticalHighPercentage ? Number.parseFloat(criticalHighPercentage) : criticalHighPercentage;
      }

      return vals;      
    })
  }
}

export const deleteNotRuleValues = ({
  selectedFilterIndex,
  unit,
  category,
  alternativeNames,
  name,
  ...otherValues
} = {}) => {
  return {
    ...otherValues,
  };
}

export const getRuleValuesFromForm = ({
  selectedFilterIndex,
  unit,
  category,
  alternativeNames,
  label,
  shortName,
  name,
  filters,
  ...otherValues
} = {}) => {

  return {
    ...otherValues,
    filters: filters.map(({ recommendations = {}, ...filters }) => {
      let newRecommendations = {};

      Object.keys(recommendations).forEach((key) => {
        const valuesArray = recommendations[key];
        if (!valuesArray?.length) return;
        const ruleArray = valuesArray.filter(({ id }) => !!checkOnDefined(id));
        if (!ruleArray.length) return;
        newRecommendations[key] = ruleArray;
      })

      return {
        ...filters,
        recommendations: newRecommendations
      }
    })
  };
}

export const transformGroupsToTabs = ({
  isCustomTabs,
  allTabsArray,
  ranges,
  low,
  high,
  groups,
  allTabsMap,
}) => {
  if (isCustomTabs) {
    return allTabsArray.filter(({ rangesToApply }) => {
      return rangesToApply.some(range => checkOnDefined(ranges[range]));
    })
  }

  const tabsWithRanges = allTabsArray.filter(({ rangesToApply }) => {
    return rangesToApply.some(range => checkOnDefined(ranges[range]));
  })

  if (!low.length && !high.length) return tabsWithRanges;

  if (!tabsWithRanges.length) return;

  const tabsWithoutGroups = tabsWithRanges.filter(({
    idForGroup,
    groupName
  }) => !groups[groupName]?.includes(idForGroup))


  if (low.length) {
    const groupTab = generateGroupTab(groups.low, allTabsMap)
    tabsWithoutGroups.splice(groups.low[0] - 1, 0, groupTab)
  }

  if (high.length) {
    const groupTab = generateGroupTab(groups.high, allTabsMap)
    tabsWithoutGroups.splice(groups.high[0] - 1, 0, groupTab)
  }

  return tabsWithoutGroups;
}

export const prepareSkinValues = ({ unitId, filters, ...preparedValues }) => {
  const valuesToSend = { ...preparedValues };
  const {
    groups,
    interactions,
    name,
    whatCanYouDo,
    sexes,
    ages,
    ethnicities,
    otherFeatures,
    whatAreTheRisks,
    idealSkinTypes,
    notMeantForSkinTypes,
    contradictions,
    resultSummary,
    ...otherData
  } = filters[0];

  const relevantResultSummary = {};

  SKIN_RANGES_VIEW_SECTIONS.forEach(({ rangesToApply, summaryKey }) => {
    if (rangesToApply.some((range) => checkOnDefined(otherData[range]))) {
      relevantResultSummary[summaryKey] = resultSummary[summaryKey];
    }
  })

  valuesToSend.filters = [{ ...otherData, criticalHighMax: 0, resultSummary: relevantResultSummary }]
  return valuesToSend
}

export const prepareDNAValues = ({ unitId, filters, ...preparedValues }) => {
  const valuesToSend = { ...preparedValues };
  const {
    groups,
    interactions,
    name,
    whatCanYouDo,
    sexes,
    ages,
    ethnicities,
    otherFeatures,
    whatAreTheRisks,
    idealSkinTypes,
    notMeantForSkinTypes,
    contradictions,
    ...otherData
  } = filters[0];

  valuesToSend.filters = [{ ...otherData }]
  return valuesToSend
}
