import React, { useCallback, useEffect, useState } from 'react'
import { Formik } from "formik";
import FormikInput from "../../../../base/components/FormikInput";
import { buildDynamicValidation, getEndpointType } from "./fromValidation";
import { UncontrolledTooltip } from "reactstrap";
import Button from "../../../../base/components/Button";
import { BUTTON_COLORS } from "../../../../base/components/Button/appearance";
import Icon from "../../../../base/components/Icon";
import { v4 as uuidv4 } from 'uuid';
import joinClassNames from "../../../../base/helpers/joinClassNames";
import FormikReactSelect from "../../../../base/components/FormikReactSelect";
import {
  CATEGORY_OPTIONS,
  MAX_ALTERNATIVE_NAMES,
  UNIT_OPTIONS,
  MAX_BIOMARKERS_NAME_LENGTH,
  arrayOfRichTextSections,
  MAX_BIOMARKERS_LABEL_NAME_LENGTH,
  MAX_BIOMARKERS_SHORT_NAME_LENGTH,
  SKIN_CATEGORY_NAME, arrayOfSkinSections, HautAiMetricTypesArray, DNAm_CATEGORY_NAME
} from "./constants";
import { deleteItemFromObject } from "../../../../base/helpers/object";
import FilterPopup from "./components/FIlterPopup";
import RichTextEditor from "../../../../base/components/RichTextEditor";
import get from 'lodash.get';
import Ranges, { getFullNameForFilter } from "./components/Ranges";
import { useLocation, useNavigate } from "react-router-dom";
import { useLoading } from "../../../../base/hooks/useLoading";
import { useService } from "../../../../base/hooks/useService";
import BiomarkersService from "../../../../services/BiomarkersService";
import PageSpinner from "../../../../base/components/PageSpinner";
import ConfirmPopup from "../../../../base/components/ConfirmPopup";
import Recommendations from "./Recommendations";
import Interactions from "./Interactions";
import isDeepEqual from 'lodash.isequal';
import ToasterService from "../../../../services/ToastService";
import { BIOMARKERS_GROUP_LINKS } from "../config";
import {
  prepareFilterValues,
  prepareBiomarkerModel,
  prepareBiomarkerRuleModel, deleteNotRuleValues, getRuleValuesFromForm, prepareSkinValues, prepareDNAValues
} from "./helpers/transformModels";
import { replaceUrlParams } from "../../../../base/helpers/url";
import RichTextWithTabs from "./components/ResultSummaryTabs";
import {
  CAUSES_DNAm_TABS,
  CAUSES_SKIN_TABS, DNAm_RESULT_SUMMARY_SECTIONS,
  REC_RANGES_TO_APPLY_BY_ID,
  RECOMMENDATION_SECTIONS, SKIN_RESULT_SUMMARY_SECTIONS, WHAT_ARE_THE_CAUSES_TABS,
  WHAT_ARE_THE_RISKS_TABS
} from "./Recommendations/constants";
import PreventClosePage from "../../../../base/components/PreventClose";
import Rule from "./components/Rule";
import { usePowerUnitFormat } from '../../../../base/hooks/usePowerUnitFormat';
import { getErrorMessage } from '../../../../base/helpers/string';

let clearRuleValues = {};

export default function BiomarkerForm({
  id,
  isEdit,
}) {
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [isEditModal, setIsEditModal] = useState(false);
  const [isLoading, { registerPromise }] = useLoading(true);
  const [categoryOptions, updateCategoryOptions] = useState([]);
  const [unitOptions, updateUnitOptions] = useState([]);
  const [availableRuleName, updateAvailableRuleName] = useState(false);
  const [isOpenDeleteModal, updateIsOpenDeleteModel] = useState(false);
  const [availableRules, updateAvailableRules] = useState([]);
  const [allFiltersMap, updateAllFiltersMap] = useState({});
  const [initialFormValues, updateInitialFormValues] = useState({ filters: [], selectedFilterIndex: 0 });
  const [formDisabled, updateFormDisabled] = useState(false);
  const [isOpenCancelPopup, updateIsOpenCancelPopup] = useState(false);
  const [isOpenConfirmModal, updateIsOpenConfirmModal] = useState(false);
  const [ruleIsLoading, updateRuleIsLoading] = useState(false);
  const [usedRuleNames, updateUsedRuleNames] = useState({});
  const { powerFormat } = usePowerUnitFormat()

  const navigate = useNavigate();

  const { pathname } = useLocation();
  /**
   * @type {BiomarkersService}
   */
  const biomarkersService = useService(BiomarkersService);
  /**
   * @type {ToasterService}
   */
  const toastService = useService(ToasterService);

  const submitBiomarker = (values) => {
    updateFormDisabled(true);
    const {
      selectedFilterIndex,
      category,
      unit,
      alternativeNames = {},
      sex,
      ruleName,
      isActive,
      ...formValues
    } = values;
    const currentUnitId = unitOptions.find(({ value }) => value === unit)?.id;
    const currentCategoryId = categoryOptions.find(({ value }) => value === category).id;
    const isSkinCategory = category === SKIN_CATEGORY_NAME;
    const isDNACategory = category === DNAm_CATEGORY_NAME;

    const getEndpointType = () => {
      if (category === SKIN_CATEGORY_NAME) {
        return "skin"
      }

      if (category === DNAm_CATEGORY_NAME) {
        return "dna-age"
      }

      return "blood"
    };

    const endpointType = getEndpointType();

    let preparedValues = {
      categoryId: currentCategoryId,
      alternativeNames: Object.values(alternativeNames),
      unitId: currentUnitId,
      ...prepareFilterValues(formValues, { isSkinCategory, isDNACategory, category }),
    }

    if (isDNACategory) {
      preparedValues = prepareDNAValues(preparedValues);
    }

    if (isSkinCategory) {
      preparedValues = prepareSkinValues(preparedValues);
    }

    if (!isSkinCategory) {
      preparedValues.hautAiMetricType = undefined;
    }

    if (!!formValues.ruleId && !usedRuleNames[ruleName]) {
      preparedValues.ruleName = ruleName;
    }

    if (!formValues.ruleId) {
      preparedValues.ruleName = ruleName;
    }

    if (isEdit) {
      biomarkersService.editBiomarker(endpointType, id, preparedValues)
        .then(() => {
          updateFormDisabled(false);
          toastService.success("Biomarker  has been successfully saved")
          navigate(replaceUrlParams(BIOMARKERS_GROUP_LINKS.DETAILS, { id }));
        })
        .catch(() => {
          updateFormDisabled(false);
        })
      return;
    }

    preparedValues.filters.forEach((_, index) => {
      preparedValues.filters[index].id = undefined;
    })

    biomarkersService.createBloodBiomarker(endpointType, preparedValues).then(() => {
      updateFormDisabled(false);
      toastService.success("Biomarker was successfully saved.")
      navigate(BIOMARKERS_GROUP_LINKS.LIST);
    }).catch(() => {
      updateFormDisabled(false);
    })
  }

  const submitFilters = (pickedFiltersObject, setFieldValue, allFiltersArrayInForm = []) => {
    const { allSexTypes, allAgeTypes, allEthnicityTypes, allOtherFeatureTypes } = allFiltersMap;
    const filterName = getFullNameForFilter(pickedFiltersObject, {
      allAgeTypes,
      allEthnicityTypes,
      allOtherFeatureTypes,
      allSexTypes
    })

    if (allFiltersArrayInForm.length === 1 && !allFiltersArrayInForm[0].sexes) {
      setFieldValue("filters", [{ ...allFiltersArrayInForm[0], ...pickedFiltersObject, name: filterName, }])
      toastService.success("Filter was successfully created");
      return;
    }

    const valuesHavePredefinedFilter = allFiltersArrayInForm.find(({ sexes, ages, ethnicities, otherFeatures }) => {
      return sexes.length === allSexTypes.length &&
        ages.length === allAgeTypes.length &&
        ethnicities.length === allEthnicityTypes.length &&
        !otherFeatures.length;
    });

    if (allFiltersArrayInForm.length >= 1 && !!valuesHavePredefinedFilter) {
      const { sexes, ages, ethnicities, otherFeatures, ...predefinedValues } = valuesHavePredefinedFilter;
      setFieldValue("filters", [...allFiltersArrayInForm, {
        ...pickedFiltersObject,
        ...predefinedValues,
        name: filterName,
      }])
      setFieldValue("selectedFilterIndex", allFiltersArrayInForm.length);
      toastService.success("Filter was successfully created");
      return;
    }

    setFieldValue("filters", [...allFiltersArrayInForm, {
      ...pickedFiltersObject,
      name: filterName,
    }])
    setFieldValue("selectedFilterIndex", allFiltersArrayInForm.length);
    toastService.success("Filter was successfully created");
  }

  const editFilter = (pickedFiltersObject, setFieldValue, allFiltersArrayInForm = [], currentFilterIndex) => {
    const { allSexTypes, allAgeTypes, allEthnicityTypes, allOtherFeatureTypes } = allFiltersMap;
    const filterName = getFullNameForFilter(pickedFiltersObject, {
      allAgeTypes,
      allEthnicityTypes,
      allOtherFeatureTypes,
      allSexTypes
    })

    allFiltersArrayInForm[currentFilterIndex] = {
      ...allFiltersArrayInForm[currentFilterIndex],
      ...pickedFiltersObject,
      name: filterName,
    }
    setFieldValue("filters", [...allFiltersArrayInForm]);
    setIsEditModal(false);
  }

  const handleEditFilter = () => {
    setIsEditModal(true);
    setIsFilterModalOpen(true);
  }

  const getCategoriesAndUnits = useCallback(() => {
    registerPromise(Promise.all([
      biomarkersService.getCategories(),
      biomarkersService.getUnits(),
      biomarkersService.getAllFilters(),
      isEdit ? biomarkersService.getBiomarkerById(id) : Promise.resolve(),
    ])).then(([categoriesResponse, unitsResponse, filtersResponse, biomarker]) => {
      updateCategoryOptions(categoriesResponse.categories.map(({ id, name }) => ({ id, value: name, label: name })))
      updateUnitOptions(unitsResponse.units.map(({ id, unit }) => ({ id, value: unit, label: powerFormat(unit) })))
      updateAllFiltersMap(filtersResponse)
      if (isEdit && biomarker) {
        const biomarkerModel = prepareBiomarkerModel(biomarker, filtersResponse);
        if (biomarkerModel && getEndpointType(biomarkerModel) === "skin" || getEndpointType(biomarkerModel) === "dna-age" && biomarkerModel.filters.length > 0) {
          for (let i = 0; i < biomarkerModel.filters.length; i++) {
            biomarkerModel.filters[i].criticalHighMax = 0;
          }
        }
        updateInitialFormValues(biomarkerModel)
        clearRuleValues = deleteNotRuleValues(biomarkerModel);
      }
    }).catch(() => {
      updateCategoryOptions(CATEGORY_OPTIONS);
      updateUnitOptions(UNIT_OPTIONS.map(i => ({ ...i, label: powerFormat(i.label) })));
    })
  }, [pathname, id, isEdit]);

  const handleDeleteRule = (ruleId, formValues, setFieldValue) => {
    if (!ruleId) return
    if (formValues.ruleId === ruleId) {
      setFieldValue('ruleId', undefined);
      setFieldValue('ruleName', undefined);
      setFieldValue('filters', []);
      updateAvailableRuleName(false);
    }
    const deletedRule = availableRules.find(({ id }) => id === ruleId);
    updateAvailableRules(prevState => prevState.filter(({ id }) => id !== ruleId));
    biomarkersService.deleteRuleById(ruleId).catch(() => {
      updateAvailableRules(prevState => [...prevState, deletedRule])
    })
  }

  const handleSelectRule = (rule, setFieldValue, values) => {
    if (!rule.id || values.ruleId === rule.id) return;
    updateRuleIsLoading(true)
    biomarkersService.getRuleById(rule.id)
      .then((data) => {
        const ruleData = prepareBiomarkerRuleModel(data, allFiltersMap);

        const { name, id, filters } = ruleData;
        setFieldValue("filters", filters)
        setFieldValue("ruleId", id)
        setFieldValue("ruleName", name)
        setFieldValue("selectedFilterIndex", 0);
        updateAvailableRuleName(false);

        clearRuleValues = {
          ...deleteNotRuleValues(values),
          filters,
          ruleId: id,
          ruleName: name,
        }
      })
      .finally(() => {
        updateRuleIsLoading(false);
      })
  }

  useEffect(() => {
    getCategoriesAndUnits()
  }, [getCategoriesAndUnits]);

  if (isLoading) return <PageSpinner />

  return (
    <Formik
      initialValues={initialFormValues}
      validate={buildDynamicValidation}
      validateOnBlur
      enableReinitialize
      onSubmit={(values) => {
        if (isEdit) {
          updateIsOpenConfirmModal(true)
          return
        }
        submitBiomarker(values)
      }}
    >
      {({ errors, handleSubmit, values, setFieldValue }) => {

        const ruleValues = getRuleValuesFromForm(values);

        if (values.ruleId &&
          !availableRuleName &&
          !isDeepEqual(ruleValues, clearRuleValues)
        ) {
          updateAvailableRuleName(true);
        }

        const isRuleNameExists = usedRuleNames[values.ruleName];
        const keyForRecommendations = `filters[${values.selectedFilterIndex}].recommendations`
        const keyForResultSummary = `filters[${values.selectedFilterIndex}].resultSummary`
        const keyForWhatAreTheRisks = `filters[${values.selectedFilterIndex}].whatAreTheRisks`
        const keyForWhatAreTheCauses = `filters[${values.selectedFilterIndex}].whatAreTheCauses`
        const keyForGroups = `filters[${values.selectedFilterIndex}].groups`

        const ranges = get(values, `filters[${values.selectedFilterIndex}]`, {});
        const groups = get(values, keyForGroups, {});
        const resultSummary = get(values, keyForResultSummary, {});
        const whatAreTheRisks = get(values, keyForWhatAreTheRisks, {});
        const whatAreTheCauses = get(values, keyForWhatAreTheCauses, {});

        const isSkinCategory = values.category === SKIN_CATEGORY_NAME;
        const isDNAmCategory = values.category === DNAm_CATEGORY_NAME;

        const resultsSummaryTabs = () => {
          if (isSkinCategory) return SKIN_RESULT_SUMMARY_SECTIONS;
          if (isDNAmCategory) return DNAm_RESULT_SUMMARY_SECTIONS;
          return RECOMMENDATION_SECTIONS;
        }

        const whatAreTheCausesTabs = () => {
          if (isSkinCategory) return CAUSES_SKIN_TABS;
          if (isDNAmCategory) return CAUSES_DNAm_TABS;
          return WHAT_ARE_THE_CAUSES_TABS;
        }

        const getNoRangePlaceholder = () => {

          if (isDNAmCategory || isSkinCategory) {
            return "Please fill at least one range to add What are the causes?"
          }

          return "Please fill at least one of the following ranges to add the What are the causes? critical low, low, high, critical high"

        };


        return (
          <form className={joinClassNames("form-horizontal p-2", formDisabled && "pointer-events-none")}
            onSubmit={handleSubmit}>

            <PreventClosePage
              shouldShowCloseAlert={!isDeepEqual(initialFormValues, values)}
            />

            {isFilterModalOpen &&
              <FilterPopup
                isOpen={isFilterModalOpen}
                updateIsOpen={setIsFilterModalOpen}
                submitFilters={(pickedFiltersObject) => submitFilters(pickedFiltersObject, setFieldValue, values.filters)}
                isEditModal={isEditModal}
                currentFilters={isEditModal && values?.filters[values.selectedFilterIndex ?? 0]}
                handleEditFilters={(pickedFiltersObject) =>
                  editFilter(pickedFiltersObject, setFieldValue, values.filters, values.selectedFilterIndex)}
                allFiltersMap={allFiltersMap}
                previousFilters={values.filters}
              />
            }

            {isOpenDeleteModal &&
              <ConfirmPopup
                isOpen={isOpenDeleteModal}
                updateIsOpen={updateIsOpenDeleteModel}
                onSubmit={() => {
                  handleDeleteRule(isOpenDeleteModal, values, setFieldValue)
                }}
                title="Delete"
                description="Are you sure you want to delete the chosen Rule?"
                submitBtnText="Delete"
              />
            }

            {isOpenCancelPopup &&
              <ConfirmPopup
                isOpen={isOpenCancelPopup}
                updateIsOpen={updateIsOpenCancelPopup}
                onSubmit={() => {
                  navigate(isEdit ? replaceUrlParams(BIOMARKERS_GROUP_LINKS.DETAILS, { id }) : BIOMARKERS_GROUP_LINKS.LIST)
                }}
                title="Leave"
                description="Are you sure you want to leave the page without saving?"
                submitBtnText="Leave"
                className="w-100"
              />
            }

            {isOpenConfirmModal &&
              <ConfirmPopup
                isOpen={isOpenConfirmModal}
                updateIsOpen={updateIsOpenConfirmModal}
                onSubmit={() => {
                  submitBiomarker(values)
                }}
                title="Confirmation"
                description="Are you sure you want to save the changes?"
                submitBtnText="Save"
                className="w-100"
              />
            }

            <FormikInput
              maxLength={MAX_BIOMARKERS_NAME_LENGTH}
              name="name"
              placeholder="Please enter a full biomarker’s name (required)"
              label="Full name"
              containerClassName="w-75"
              id="name"
            />

            <FormikInput
              maxLength={MAX_BIOMARKERS_LABEL_NAME_LENGTH}
              name="label"
              placeholder="Please enter up to 4 characters for Biomarker’s label (required)"
              label="Label name"
              containerClassName="w-75 mt-3"
              id="label"
            />

            <FormikInput
              maxLength={MAX_BIOMARKERS_SHORT_NAME_LENGTH}
              name="shortName"
              placeholder="Biomarker that User will sees clicking the Biomarker (required)"
              label="Short name"
              containerClassName="w-75 mt-3"
              id="shortName"
            />

            <section className="d-flex flex-wrap">
              {Object.keys(values.alternativeNames ?? {})?.map((uuid, index) => {
                return (
                  <section className={joinClassNames("w-50 d-flex mt-3 position-relative", index % 2 && "ps-3")}
                    key={uuid}>
                    <FormikInput
                      name={`alternativeNames.${uuid}`}
                      id={uuid}
                      label={`Alternative name ${index + 1}`}
                      placeholder={`Biomarker’s alternative name ${index + 1}`}
                      maxLength={MAX_BIOMARKERS_NAME_LENGTH}
                      containerClassName="w-100"
                    />

                    <div className="trash-icon-placeholder" />
                    <Icon
                      icon="trashIcon"
                      className="text-danger cursor-pointer input-trash-icon ms-3"
                      onClick={() => {
                        setFieldValue("alternativeNames", deleteItemFromObject(values.alternativeNames, uuid))
                      }}
                    />
                  </section>
                )
              })}
            </section>

            {!!values.name?.length &&
              <>
                <section id="addNewAlternativeName" className="w-fit-content">
                  <Button
                    color={BUTTON_COLORS.transparent}
                    className="no-border text-primary mt-1 ps-0"
                    onClick={() =>
                      setFieldValue(
                        "alternativeNames",
                        { ...(values.alternativeNames ?? {}), [uuidv4()]: "" }
                      )
                    }
                    disabled={
                      Object.keys(values.alternativeNames ?? {})?.length >= MAX_ALTERNATIVE_NAMES ||
                      Object.values(values.alternativeNames ?? {})?.some(value => !value)
                    }
                  >
                    <i className="bx bx-plus" /> Add alternative name
                  </Button>
                </section>

                <UncontrolledTooltip
                  popperClassName={joinClassNames(
                    "tooltip-alternative-name",
                    Object.keys(values.alternativeNames ?? {})?.length < MAX_ALTERNATIVE_NAMES && "visibility-hidden"
                  )
                  }
                  placement="bottom"
                  target="addNewAlternativeName"
                >
                  Max. 10 alternative names can be added
                </UncontrolledTooltip>
              </>
            }

            <FormikReactSelect
              setFieldValue={setFieldValue}
              options={categoryOptions}
              name="category"
              className="w-75 custom-select"
              placeholder="Select category (required)"
              value={categoryOptions.find(({ value }) => value === values.category)}
              label="Category"
              withError
              containerClassName="mt-3"
              afterOnChange={(value) => {
                const becomeSkin = value === SKIN_CATEGORY_NAME && values.category !== SKIN_CATEGORY_NAME;
                const becomeNotSkin = value !== SKIN_CATEGORY_NAME && values.category === SKIN_CATEGORY_NAME;

                const becomeDNAm = value === DNAm_CATEGORY_NAME && values.category !== DNAm_CATEGORY_NAME
                const becomeNotDNAm = value !== DNAm_CATEGORY_NAME && values.category === DNAm_CATEGORY_NAME

                if (becomeDNAm || becomeNotDNAm || becomeSkin || becomeNotSkin) {
                  setFieldValue("filters", [])
                  setFieldValue("ruleId", undefined)
                  setFieldValue("ruleName", undefined)
                  updateAvailableRuleName(false)
                }

                if (becomeSkin || becomeNotSkin) {
                  setFieldValue("hautAiMetricType", undefined)
                }
              }}
            />

            {!isDNAmCategory && <>
              {isSkinCategory ?
                <FormikReactSelect
                  setFieldValue={setFieldValue}
                  options={HautAiMetricTypesArray}
                  name="hautAiMetricType"
                  className="w-75 custom-select"
                  placeholder="Select corresponding Haut.ai metric (required)"
                  value={HautAiMetricTypesArray.find(({ value }) => value === values.hautAiMetricType)}
                  label="Corresponding Haut.ai metric"
                  withError
                  containerClassName="mt-3"
                />
                :
                <FormikReactSelect
                  setFieldValue={setFieldValue}
                  options={unitOptions}
                  name="unit"
                  className="w-75 custom-select"
                  placeholder="Select unit (required)"
                  value={unitOptions.find(({ value }) => value === values.unit)}
                  label="Unit"
                  withError
                  containerClassName="mt-3"
                />
              }
            </>
            }

            <label className="form-values-label">Values and Recommendations</label>
            <p className="form-values-desc">Enter biomarker values, add recommendations, set or create rules for
              them.</p>

            <Rule
              handleSelectRule={(item) => handleSelectRule(item, setFieldValue, values)}
              values={values}
              updateIsOpenDeleteModel={updateIsOpenDeleteModel}
              availableRules={availableRules}
              availableRuleName={availableRuleName}
              setFieldValue={setFieldValue}
              updateAvailableRuleName={updateAvailableRuleName}
              usedRuleNames={usedRuleNames}
              isRuleNameExists={isRuleNameExists}
              isSkinCategory={isSkinCategory}
              isDNAmCategory={isDNAmCategory}
              updateAvailableRules={updateAvailableRules}
              updateUsedRuleNames={updateUsedRuleNames}
            />

            <Ranges
              openFiltersModal={() => {
                setIsEditModal(false);
                setIsFilterModalOpen(true);
              }}
              disabled={ruleIsLoading}
              filters={values.filters ?? []}
              setFieldValue={setFieldValue}
              unit={values.unit}
              selectedFilterIndex={values.selectedFilterIndex}
              handleEditFilter={handleEditFilter}
              allFiltersMap={allFiltersMap}
              groups={get(values, keyForGroups, {})}
              keyForGroups={keyForGroups}
              recommendations={get(values, keyForRecommendations, {})}
              isSkinCategory={isSkinCategory}
              isDNAmCategory={isDNAmCategory}
              errors={errors}
              category={values.category}
            />
            {errors?.filters?.[values.selectedFilterIndex]?.totalPercentage &&
              (values?.filters?.[values.selectedFilterIndex]?.criticalLowPercentage ||
                values?.filters?.[values.selectedFilterIndex]?.lowPercentage ||
                values?.filters?.[values.selectedFilterIndex]?.subOptimalPercentage ||
                values?.filters?.[values.selectedFilterIndex]?.optimalPercentage ||
                values?.filters?.[values.selectedFilterIndex]?.supraOptimalPercentage ||
                values?.filters?.[values.selectedFilterIndex]?.highPercentage ||
                values?.filters?.[values.selectedFilterIndex]?.criticalHighPercentage)
              && (
                <div className="invalid-feedback d-block w-75 text-end fs-6">
                  {errors?.filters?.[values.selectedFilterIndex].totalPercentage}
                </div>
              )}
            <Recommendations
              recommendations={get(values, keyForRecommendations, {})}
              setFieldValue={setFieldValue}
              keyForRecommendations={keyForRecommendations}
              ranges={ranges}
              groups={groups}
              keyForGroups={keyForGroups}
              keyForResultSummary={keyForResultSummary}
              resultSummary={resultSummary}
              isSkinCategory={isSkinCategory}
              isDNAmCategory={isDNAmCategory}
            />

            <p className="about-biomarker">About biomarker</p>

            <RichTextWithTabs
              ranges={ranges}
              setFieldValue={setFieldValue}
              keyForEdit={keyForResultSummary}
              valuesObject={resultSummary}
              allTabsArray={resultsSummaryTabs()}
              allTabsMap={(isSkinCategory || isDNAmCategory) ? {} : REC_RANGES_TO_APPLY_BY_ID}
              groups={(isSkinCategory || isDNAmCategory) ? {} : groups}
              label="Result Summary"
              placeholder="Enter information to customer about their result (required)"
              description="Enter information to customer about their result."
              noRangePlaceholder="Please fill at least one range to add Result summary"
            />

            {((isSkinCategory || isDNAmCategory) ? arrayOfSkinSections : arrayOfRichTextSections).map(({
              itemKey,
              label,
              description,
              placeholder
            }) => {
              const keyForEditor = `filters[${values.selectedFilterIndex}].${itemKey}`;
              return (
                <RichTextEditor
                  key={itemKey}
                  value={get(values, keyForEditor, "") ?? ""}
                  onChange={(text) => {
                    setFieldValue(keyForEditor, text)
                  }}
                  name={keyForEditor}
                  label={isDNAmCategory ? "About biomarker" : label}
                  description={description}
                  disabled={ruleIsLoading}
                  placeholder={placeholder}
                />
              )
            })}

            {!isSkinCategory && !isDNAmCategory &&
              <RichTextWithTabs
                ranges={ranges}
                setFieldValue={setFieldValue}
                keyForEdit={keyForWhatAreTheRisks}
                valuesObject={whatAreTheRisks}
                groups={groups}
                isCustomTabs
                allTabsArray={WHAT_ARE_THE_RISKS_TABS}
                allTabsMap={{}}
                className="mt-4"
                label="What are the risks?"
                placeholder="Enter information about the main risks and diseases. Add a link to the evidence or research..."
                description="Enter information about the main risks and diseases. Add a link to the evidence or research."
                noRangePlaceholder="Please fill at least one of the following ranges to add the What are the risks?: critical low, low, high, critical high"
                withBulletList
                id={"whatAreTheRisks"}
              />
            }

            <RichTextWithTabs
              ranges={ranges}
              setFieldValue={setFieldValue}
              keyForEdit={keyForWhatAreTheCauses}
              valuesObject={whatAreTheCauses}
              groups={groups}
              isCustomTabs
              allTabsArray={whatAreTheCausesTabs()}
              allTabsMap={{}}
              className="mt-4"
              label="What are the causes?"
              placeholder="Enter information about the main causes. Add a link to the evidence or research..."
              description="Enter information about the main causes. Add a link to the evidence or research."
              noRangePlaceholder={getNoRangePlaceholder()}
              withBulletList
              id={"whatAreTheCauses"}
            />

            {!isSkinCategory && !isDNAmCategory &&
              <Interactions
                setFieldValue={setFieldValue}
                interactions={get(values, `filters[${values.selectedFilterIndex}].interactions`)}
                keyForInteractions={`filters[${values.selectedFilterIndex}].interactions`}
              />
            }

            <section className="w-100 d-flex align-items-center justify-content-end mt-5">
              <Button
                color={BUTTON_COLORS.primaryOutline}
                onClick={() => {
                  if (isDeepEqual(initialFormValues, values)) {
                    navigate(isEdit ? replaceUrlParams(BIOMARKERS_GROUP_LINKS.DETAILS, { id }) : BIOMARKERS_GROUP_LINKS.LIST)
                    return;
                  }
                  updateIsOpenCancelPopup(true)
                }}
              >
                Cancel
              </Button>

              <Button
                color={BUTTON_COLORS.primary}
                className={joinClassNames("ms-3", formDisabled && "px-5")}
                type="submit"
                loading={formDisabled}
              >
                Save biomarker
              </Button>
            </section>
            {Object.keys(errors).length > 0 && <section className="w-100 d-flex align-items-center">
              <div className="text-danger">Please fix the following errors:
                <br />
                {getErrorMessage(errors)}</div>
            </section>
            }
          </form>
        )
      }}
    </Formik>
  )
}
