import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Card, CardBody, Col, Row } from "reactstrap";
import Breadcrumb from "../../../base/components/Common/Breadcrumb2";
import { CUSTOMER_MANAGEMENT_LINKS } from "./config";
import { useLoading } from "../../../base/hooks/useLoading";
import { CSV_FILE_MASK, DEFAULT_DEBOUNCE_DELAY } from "../../../base/constants/shared";
import { useDebounce } from "../../../base/hooks/useDebounce";
import Table from "../../../base/components/Table";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import FilePopup from "../CustomerResults/ResultsList/FilePopup";
import ResultsPopupBlood from "../CustomerResults/ResultsList/ResultsPopupBlood";
import { STATUS_IDS } from "../CustomerResults/ResultsList/components";
import ConfirmPopup, { ConfirmPopupWithReason } from "../../../base/components/ConfirmPopup";
import {
  useSortProvider,
  usePaginationProvider,
  useSearchProvider,
  useFilterProvider,
  useLocationSource,
  parseSorting
} from "../../../base/components/Table/hooks";
import { useQueryString } from "../../../base/hooks/useQueryString";
import { useService } from "../../../base/hooks/useService";
import Button from "../../../base/components/Button";
import PageSpinner from "../../../base/components/PageSpinner";
import UserResultsService from "../../../services/UserResults";
import UsersService from "../../../services/UsersService";
import { BUTTON_COLORS } from "../../../base/components/Button/appearance";
import ValueWithLabel from "./Details/ValueWIthLabel";
import { GENDERS, DEFAULT_TABLE_LIMIT } from "../../../base/constants/shared";
import { columns, SearchPlaceholder, TableHeader } from "./Details/components"
import UploadFileModal from './UsersList/UploadFileModal';
import { replaceUrlParams } from '../../../base/helpers/url';
import FileUpload from "../CustomerResults/ResultsList/FileUpload";
import TagsPopup from './Details/TagsPopup';
import { CUSTOMER_RESULTS_CATEGORIES } from "../CustomerResults/constants";
import ResultsPopupFoodIntolerance from "../CustomerResults/ResultsList/ResultsPopupFoodIntolerance";
import ToasterService from "../../../services/ToastService";
import CsvViewer from "../CustomerResults/ResultsList/CsvViewer";
import PDFFilePopup from "../CustomerResults/ResultsList/PDFFilePopup";

const breadcrumbs = {
  title: "Customer’s detailed page",
  breadcrumbItems: [
    { title: "Customer Management", link: CUSTOMER_MANAGEMENT_LINKS.LIST },
    { title: "Customer’s detailed page" }
  ]
}

const LIMIT_OPTIONS = [
  { label: "15", value: 15 },
  { label: "30", value: 30 },
  { label: "50", value: 50 },
  { label: "100", value: 100 },
]

const resultPopupComponents = {
  [CUSTOMER_RESULTS_CATEGORIES.BLOOD_CATEGORY]: ResultsPopupBlood,
  [CUSTOMER_RESULTS_CATEGORIES.FOOD_INTOLERANCE_CATEGORY]: ResultsPopupFoodIntolerance,
}

export default function Details() {
  /**
   * @type {UsersService}
   */
  const usersService = useService(UsersService);
  /**
   * @type {UserResultsService}
   */
  const userResultsService = useService(UserResultsService);
  /**
   * @type {ToasterService}
   */
  const toastService = useService(ToasterService);

  const { search: locationSearch } = useLocation();
  const [user, setUser] = useState({});
  const [collapsedRows, updateCollapsedRows] = useState([]);

  const [isCsvPopupOpen, setIsCsvPopupOpen] = useState(false);
  const [userResultDetails, updateUserResultDetails] = useState({})
  const [isOpenDetailsPopup, updateIsOpenDetailsPopup] = useState(false);
  const [isOpenPDFFilesPopup, updateIsOpenPDFFilesPopup] = useState(false);
  const [isOpenTagPopup, setIsOpenTagPopup] = useState(false);
  const [showConfirmVerifyPopup, updateShowConfirmVerifyPopup] = useState(false);
  const [showCancelVerifyPopup, updateShowCancelVerifyPopup] = useState(false);
  const [showConfirmReprocessPopup, updateShowReprocessPopup] = useState(false);
  const [isOpenResultsPopup, updateIsOpenResultsPopup] = useState(false);
  const [cancellationReason, updateCancellationReason] = useState();
  const [isOpenCancellationReasonPopup, updateIsOpenCancellationReasonPopup] = useState(false);
  const [isViewCsvOpen, setIsViewCsvOpen] = useState(false);

  const [labResults, setLabResults] = useState([]);
  const [resultsPagination, setResultsPagination] = useState([]);
  const [lastResultDates, setLastResultDates] = useState([]);

  const [isLoading, { registerPromise }] = useLoading(false);
  const [isTableLoading, { registerPromise: registerTablePromise }] = useLoading(true);
  const { id } = useParams();
  const navigate = useNavigate();
  const [isUploadDNAFileModalOpen, setIsUploadDNAFileModalOpen] = useState(false);

  const locationSource = useLocationSource();

  const {
    params: {
      search,
      limit = DEFAULT_TABLE_LIMIT,
      offset = 0,
      testProductName,
      orderId,
      sampleCode,
      activatedAt,
      sampleAt,
      labReceivedAt,
      resultAt,
      id: testId,
      lab,
      labId,
      status
    }
  } = useQueryString(locationSearch)

  const paginationProvider = usePaginationProvider({
    source: locationSource,
    alias: "offset",
    scope: "",
    fallback: 0
  });

  const limitProvider = usePaginationProvider({
    source: locationSource,
    alias: "limit",
    scope: "",
    fallback: 15,
    onApplyClearScope: ["offset"]
  });

  const searchProvider = useSearchProvider({
    source: locationSource,
    scope: "",
    alias: 'search',
    onApplyClearScope: [""]
  });

  const filterProvider = useFilterProvider({
    source: locationSource,
    scope: "",
    alias: 'categoryIds',
    onApplyClearScope: ["offset"]
  })

  const generateSortProviderParams = (name) => {
    const allParams = [
      "testProductName",
      "orderId",
      "sampleCode",
      "activatedAt",
      "sampleAt",
      "labReceivedAt",
      "resultAt",
      "id",
      "lab",
      "labId",
      "status"
    ];
    return {
      source: locationSource,
      alias: name,
      scope: "",
      onApplyClearScope: allParams.filter(paramName => paramName !== name)
    }
  }

  const nameSortProvider = useSortProvider(generateSortProviderParams("testProductName"));
  const orderIdSortProvider = useSortProvider(generateSortProviderParams("orderId"));
  const sampleCodeSortProvider = useSortProvider(generateSortProviderParams("sampleCode"));
  const activatedAtSortProvider = useSortProvider(generateSortProviderParams("activatedAt"));
  const sampleAtSortProvider = useSortProvider(generateSortProviderParams("sampleAt"));
  const labReceivedAtSortProvider = useSortProvider(generateSortProviderParams("labReceivedAt"));
  const resultAtSortProvider = useSortProvider(generateSortProviderParams("resultAt"));
  const idSortProvider = useSortProvider(generateSortProviderParams("id"));
  const labSortProvider = useSortProvider(generateSortProviderParams("lab"));
  const labIdSortProvider = useSortProvider(generateSortProviderParams("labId"));
  const statusSortProvider = useSortProvider(generateSortProviderParams("status"));

  const toggleCollapse = (id) => {
    if (collapsedRows.includes(id)) {
      updateCollapsedRows(prevState => prevState.filter(item => item !== id))
      return
    }
    updateCollapsedRows(prevState => [...prevState, id]);
  }

  const ResultPopup = useMemo( () => {
    // -- if sampleCode is not exist -> it's manually uploaded PDF Results --
    if (typeof isOpenResultsPopup == "object" && !isOpenResultsPopup?.sampleCode) {
      isOpenResultsPopup.category = CUSTOMER_RESULTS_CATEGORIES.BLOOD_CATEGORY;
    }
    return resultPopupComponents[isOpenResultsPopup.category]
  }, [isOpenResultsPopup])

  const isUserResult = useCallback((r) => {
    return r.blood !== undefined;
  }, [])

  const isUserSkinResult = useCallback((r) => {
    return isUserResult(r) === false;
  }, [isUserResult])

  const [debouncedSearch] = useDebounce(search, DEFAULT_DEBOUNCE_DELAY, () => paginationProvider.setValue(0));

  const fetchTable = useCallback(
    () => {
      const sortObject = parseSorting({
        testProductName,
        orderId,
        sampleCode,
        activatedAt,
        sampleAt,
        labReceivedAt,
        resultAt,
        id: testId,
        lab,
        labId,
        status
      });
      const params = {
        limit,
        offset,
        q: debouncedSearch,
        ...sortObject
      };
      return registerTablePromise(
        usersService
          .getUserLabsResults(id, params)
          .then(({ data, pagination }) => {
            setLabResults(data);
            setResultsPagination(pagination);
          })
      );
    },
    [
      id,
      offset,
      limit,
      testProductName,
      orderId,
      sampleCode,
      activatedAt,
      sampleAt,
      labReceivedAt,
      resultAt,
      testId,
      lab,
      labId,
      status,
      debouncedSearch
    ],
  )

  const fetchUser = useCallback(
    () => {
      usersService.getUserById(id).then((data) => {
        setUser(data)
      })
    },
    [id],
  )

  const fetchDates = useCallback(
    () => {
      return usersService
        .getLabsDates(id)
        .then((data) => {
          setLastResultDates(data);
        })
    },
    [id],
  )

  const handleMarkAsReceivedByLab = (id) => {
    return userResultsService.receivedByLab(id)
        .then(() => {
          fetchTable()
          toastService.success("Kit status updated to In Progress")
        })
  }

  const reprocessHL7 = useCallback((id) => {
    registerTablePromise(userResultsService.reprocessHL7(id).then(fetchTable))
  }, [id])

  const changeUserResultStatus = useCallback((id, status, additionalValues = {}) => {
    registerTablePromise(userResultsService.changeResultStatus(id, { status, ...additionalValues })
      .then(fetchTable))
  }, [])

  useEffect(() => {
    registerPromise(Promise.all([
      fetchUser(),
      fetchDates(),
    ]))
  }, [id]);

  useEffect(() => {
    fetchTable()
  }, [fetchTable]);

  return (
    <>
      <Breadcrumb {...breadcrumbs} />
      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              {isLoading ?
                <PageSpinner />
                :
                <>
                  <section className="d-flex align-items-center justify-content-between mb-4">
                    <label className="font-weight-semibold font-size-15">{user.firstName} {user.lastName}</label>
                    <div className='d-flex'>
                      <Button
                        color={BUTTON_COLORS.primaryOutline}
                        onClick={() => setIsOpenTagPopup(true)}
                        className="d-flex align-items-center me-2"
                      >
                        Latest assessment data
                      </Button>
                      <Button
                        color={BUTTON_COLORS.primary}
                        onClick={() => setIsUploadDNAFileModalOpen((prevState) => !prevState)}
                        className="d-flex align-items-center me-2"
                      >
                        <i className="bx bx-cloud-upload me-2" />
                        Upload DNA file
                      </Button>
                      <Button
                        color={BUTTON_COLORS.primary}
                        onClick={() => setIsCsvPopupOpen({userId: id})}
                        className="d-flex align-items-center me-2"
                      >
                        <i className="bx bx-cloud-upload me-2" />
                        Upload Food Intolerance file
                      </Button>
                      <Button
                        color={BUTTON_COLORS.primary}
                        onClick={() => navigate(replaceUrlParams(CUSTOMER_MANAGEMENT_LINKS.UPLOAD_MANUALLY, { id }))}
                        className="d-flex align-items-center me-2"
                      >
                        <i className="bx bx-cloud-upload me-2" />
                        Upload Manually
                      </Button>
                    </div>
                  </section>

                  <section className='d-flex flex-wrap flex-row mb-4 p-relative'>
                    <ValueWithLabel
                      value={user.email}
                      label="Email"
                      className={"min-w-1-4 mb-3"}
                    />

                    <ValueWithLabel
                      value={GENDERS[user?.additionalField?.sex]}
                      label="Gender"
                      className={"min-w-1-4"}
                    />

                    <ValueWithLabel
                      value={user.id}
                      label="ID"
                      className={"min-w-1-4"}
                    />

                    <ValueWithLabel
                      value={user?.additionalField?.dateOfBirth.split('-').reverse().join('/')}
                      label="Birth date"
                      className={"min-w-1-4"}
                    />

                    <ValueWithLabel
                      value={user?.createdAt?.split('T')[0].split('-').reverse().join('/')}
                      label="Join date"
                      className={"min-w-1-4"}
                    />

                    <ValueWithLabel
                      value={lastResultDates.filter(isUserResult)?.[0]?.blood?.split('-').reverse().join('/')}
                      label="Health assessment date"
                      className={"min-w-1-4"}
                    />

                    <ValueWithLabel
                      value={lastResultDates.filter(isUserSkinResult)?.[0]?.skin?.split('T')[0].split('-').reverse().join('/')}
                      label="Skin assessment date"
                      className={"min-w-1-4"}
                    />

                    <ValueWithLabel
                      value={user?.pdfFilesCount || 0}
                      label="Uploaded Files"
                      className={"min-w-1-4"}
                    />

                    <section className={"view-files"}>
                      <a onClick={() => {
                        updateIsOpenPDFFilesPopup(true);
                      }}>View Files</a>
                    </section>
                  </section>
                  <Table
                    columns={columns}
                    data={labResults}
                    isAddNewBiomarker
                    loading={isTableLoading}
                    HeaderComponent={TableHeader}
                    totalCount={resultsPagination.totalCount}

                    searchProvider={searchProvider}
                    filterProvider={filterProvider}
                    sortProviders={{
                      ['testProductName']: nameSortProvider,
                      ['orderId']: orderIdSortProvider,
                      ['sampleCode']: sampleCodeSortProvider,
                      ['activatedAt']: activatedAtSortProvider,
                      ['sampleAt']: sampleAtSortProvider,
                      ['labReceivedAt']: labReceivedAtSortProvider,
                      ['resultAt']: resultAtSortProvider,
                      ['id']: idSortProvider,
                      ['lab']: labSortProvider,
                      ['labId']: labIdSortProvider,
                      ['status']: statusSortProvider,
                    }}

                    actions={{
                      markAsCancelled: (id) => updateShowCancelVerifyPopup(id),
                      markAsVerified: (id) => updateShowConfirmVerifyPopup(id),
                      downloadFile: (url) => url ? window.open(url, '_blank').focus() : undefined,
                      viewFiles: (row) => {
                        updateUserResultDetails(row)
                        updateIsOpenDetailsPopup(true);
                      },
                      markAsReceivedByLab: handleMarkAsReceivedByLab,
                      viewCSVFile: (row) => { setIsViewCsvOpen(row) },
                      uploadCSVFile: (row) => { setIsCsvPopupOpen(row) },
                      viewResults: (row) => updateIsOpenResultsPopup(row),
                      reprocessCSVFile: (row) => { setIsCsvPopupOpen(row) },
                      reprocessHL7: (id) => updateShowReprocessPopup(id),
                      viewCancellationReason: (reason) => {
                        updateCancellationReason(reason);
                        updateIsOpenCancellationReasonPopup(true);
                      },

                    }}

                    limit={limitProvider.getValue()}
                    limitProvider={limitProvider}
                    offset={offset}
                    paginationProvider={paginationProvider}
                    commonPlaceholder="No lab results found"
                    placeholderForSearch={<SearchPlaceholder />}
                    withLocation
                    collapsedState={collapsedRows}
                    toggleCollapse={toggleCollapse}
                    isLimitEditable={true}
                    limitOptions={LIMIT_OPTIONS}
                  />
                </>
              }
            </CardBody>
          </Card>
        </Col>
      </Row>

      {isUploadDNAFileModalOpen && (
        <UploadFileModal
          isOpen={isUploadDNAFileModalOpen}
          updateIsOpen={setIsUploadDNAFileModalOpen}
          title="Upload DNA file"
          description="Drag & drop your DNA file or click to upload."
          submitBtnText="Confirm"
          fileMask={CSV_FILE_MASK}
        />
      )}

      {!!isOpenDetailsPopup &&
        <FilePopup
          isOpen={isOpenDetailsPopup}
          updateIsOpen={updateIsOpenDetailsPopup}
          details={userResultDetails}
          afterOnClose={updateUserResultDetails}
        />
      }

      {!!isOpenPDFFilesPopup &&
        <PDFFilePopup
          isOpen={isOpenPDFFilesPopup}
          updateIsOpen={updateIsOpenPDFFilesPopup}
          user={user}
          afterOnClose={updateUserResultDetails}
        />
      }

      {!!showConfirmVerifyPopup &&
        <ConfirmPopup
          isOpen={!!showConfirmVerifyPopup}
          updateIsOpen={updateShowConfirmVerifyPopup}
          onSubmit={() => changeUserResultStatus(showConfirmVerifyPopup, STATUS_IDS.verified)}
          title="Confirmation"
          description="Are you sure you want to mark the result as verified?"
          submitBtnText="Confirm"
          className=""
        />
      }

      {!!showCancelVerifyPopup &&
        <ConfirmPopupWithReason
          isOpen={!!showCancelVerifyPopup}
          updateIsOpen={updateShowCancelVerifyPopup}
          onSubmit={(values) => changeUserResultStatus(showCancelVerifyPopup, STATUS_IDS.cancelled, values)}
          title="Confirmation"
          description="Are you sure you want to mark the result as canceled?"
          submitBtnText="Confirm"
          className=""
        />
      }

      {!!showConfirmReprocessPopup &&
        <ConfirmPopup
          isOpen={!!showConfirmReprocessPopup}
          updateIsOpen={updateShowReprocessPopup}
          onSubmit={() => reprocessHL7(showConfirmReprocessPopup)}
          title="Confirmation"
          description="Are you sure you want to reprocess HL7 file again?"
          submitBtnText="Confirm"
          className=""
        />
      }

      {!!isOpenResultsPopup && !!ResultPopup &&
        <ResultPopup
          isOpen={!!isOpenResultsPopup}
          updateIsOpen={updateIsOpenResultsPopup}
          row={isOpenResultsPopup}
        />
      }

      {!!isOpenCancellationReasonPopup &&
        <ConfirmPopup
          isOpen={!!isOpenCancellationReasonPopup}
          updateIsOpen={updateIsOpenCancellationReasonPopup}
          onSubmit={() => updateIsOpenCancellationReasonPopup(false)}
          title="Reason of cancellation"
          description={cancellationReason || "-"}
          submitBtnText="Ok"
          className=""
          showCancelButton={false}
          submitButtonClassName="px-4"
        />
      }

      {!!isCsvPopupOpen &&
        <FileUpload
          userId={isCsvPopupOpen.userId}
          testObjectId={isCsvPopupOpen.id}
          isOpen={isCsvPopupOpen !== false}
          updateIsOpen={setIsCsvPopupOpen}
          title="Upload Food Intolerance result"
          description="Drag & drop your Food Intolerance file or click to upload."
          submitBtnText="Confirm"
          fileMask={CSV_FILE_MASK}
          afterUpload={fetchTable}
        />
      }

      {!!isViewCsvOpen &&
          <CsvViewer
              isOpen={isViewCsvOpen !== false}
              updateIsOpen={setIsViewCsvOpen}
              details={isViewCsvOpen}
              afterOnClose={setIsViewCsvOpen}
          />
      }

      <TagsPopup isOpen={isOpenTagPopup} updateIsOpen={(s) => setIsOpenTagPopup(s)} />

    </>
  )
}
