import React, { useState, useEffect, useCallback } from "react";
import { useLocationQuery } from "../../../../base/hooks/useQueryString";
import {
  CustomModal,
  ModalHeader,
  ModalBody,
} from "../../../../base/components/CustomModal";
import Table from "../../../../base/components/Table";
import { useSearchParams } from "react-router-dom";
import { useParams, useLocation } from "react-router-dom";
import { DEFAULT_DEBOUNCE_DELAY } from "../../../../base/constants/shared";
import { useLoading } from "../../../../base/hooks/useLoading";
import { useQueryString } from "../../../../base/hooks/useQueryString";
import { useDebounce } from "../../../../base/hooks/useDebounce";
import { useHighlight } from "../../../../base/hooks/useHighlight";
import { formatISODate } from "../../../../base/helpers/date";
import { useService } from "../../../../base/hooks/useService";
import UsersService from "../../../../services/UsersService";
import TableSearch from "../../../../base/components/Table/tableSearch";
import {
  useSortProvider,
  usePaginationProvider,
  useSearchProvider,
  useLocationSource,
  useFilterProvider,
  parseSorting,
} from "../../../../base/components/Table/hooks";

const LIMIT_OPTIONS = [
  { label: "15", value: 15 },
  { label: "30", value: 30 },
  { label: "50", value: 50 },
  { label: "100", value: 100 },
];

const QuizMap = {
  1: "Skin",
  2: "Assesment",
};

export const columns = [
  {
    Header: "#",
    width: 50,
    Cell: ({ row: { index } }) => {
      const {
        params: { offset = 0 },
      } = useLocationQuery();
      return <label className="mb-0">{index + 1 + (offset ?? 0)}</label>;
    },
  },
  {
    Header: "Variable name",
    accessor: "key",
    width: 200,
    canSort: true,
    Cell: ({ value }) => {
      const { params: { t } } = useLocationQuery();
      const { decorateText } = useHighlight(t);
      return (
        <section className="w-fit-content d-flex align-items-center">
          <span {...decorateText(value)} />
        </section>
      );
    },
  },
  {
    Header: "Value",
    accessor: "value",
    width: 150,
    Cell: ({ value }) => (
      <section className="w-fit-content d-flex align-items-center">
        {value}
      </section>
    ),
  },
  {
    Header: "Quiz type",
    accessor: "quizType",
    width: 100,
    canSort: true,
    Cell: ({ value }) => (
      <section className="w-fit-content d-flex align-items-center">
        {QuizMap[value]}
      </section>
    ),
  },

  {
    Header: "Date",
    accessor: "createdAt",
    width: 150,
    canSort: true,
    Cell: ({ value }) => (
      <section className="w-fit-content d-flex align-items-center">
        {formatISODate(value)}
      </section>
    ),
  },
];

export const SearchPlaceholder = () => <>No variable names found</>;

export const TableHeader = ({ searchProvider }) => {
  return (
    <section className="d-flex flex-column">
      <section className="d-flex w-100 mb-3 align-items-center justify-content-start">
        <TableSearch
          className="biomarkers-search me-3"
          search={searchProvider.getValue()}
          onSearch={searchProvider.setValue}
          placeholder="Search variable name"
        />
      </section>
    </section>
  );
};

export default function TagsPopup({ isOpen, updateIsOpen }) {
  /**
   * @type {UsersService}
   */
  const usersService = useService(UsersService);
  const { search: locationSearch } = useLocation();

  const {
    params: {
      t,
      limit = LIMIT_OPTIONS[0].value,
      offset = 0,
      key,
      createdAt,
      quizType,
    },
  } = useQueryString(locationSearch);

  const [searchParams, setSearchParams] = useSearchParams();
  const locationSource = useLocationSource();
  const searchProvider = useSearchProvider({
    source: locationSource,
    scope: "",
    alias: "t",
    onApplyClearScope: [""],
  });

  const generateSortProviderParams = (name) => {
    const allParams = ["key", "createdAt", "quizType"];
    return {
      source: locationSource,
      alias: name,
      scope: "",
      onApplyClearScope: allParams.filter((paramName) => paramName !== name),
    };
  };

  const clearParams = useCallback(() => {
    const allParams = ["key", "createdAt", "t", "limit", "offset", "quizType"];
    allParams.forEach((i) => {
      searchParams.delete(i);
    });
    setSearchParams(searchParams);
  }, []);

  const filterProvider = useFilterProvider({
    source: locationSource,
    scope: "",
    alias: "categoryIds",
    onApplyClearScope: ["offset"],
  });

  const nameSortProvider = useSortProvider(generateSortProviderParams("key"));
  const quizTypeSortProvider = useSortProvider(
    generateSortProviderParams("quizType")
  );
  const createdAtSortProvider = useSortProvider(
    generateSortProviderParams("createdAt")
  );

  const { id } = useParams();
  const [isLoading, { registerPromise }] = useLoading(false);
  const [records, setRecords] = useState([]);

  const onClose = () => updateIsOpen(false);

  const fitlerSearch = useCallback((i) => {
    return i.key.includes(t || '')
  }, [t])

  const fetchRecords = useCallback(() => {
    const sortObject = parseSorting({
      key,
      createdAt,
      quizType,
    });

    return usersService
      .getUserTags(id, {
        ...sortObject,
      })
      .then(({ data }) => {
        setRecords(data);
      });
  }, [id, limit, offset, key, createdAt, quizType]);

  useEffect(() => {
    if (isOpen === true) {
      registerPromise(fetchRecords());
    }
  }, [isOpen, fetchRecords]);

  useEffect(() => {
    if (isOpen === false) {
      clearParams();
    }
  }, [isOpen, clearParams]);


  return (
    <CustomModal scrollable={true} size="lg" className="small-modal" isOpen={isOpen}>
      <ModalHeader onClose={onClose}>Latest assessment data</ModalHeader>
      <ModalBody>
        <Table
          columns={columns}
          data={records.filter(fitlerSearch)}
          loading={isLoading}
          HeaderComponent={TableHeader}
          searchProvider={searchProvider}
          sortProviders={{
            ["key"]: nameSortProvider,
            ["createdAt"]: createdAtSortProvider,
            ["quizType"]: quizTypeSortProvider,
          }}
          commonPlaceholder={<div className="position-absolute d-flex align-items-center justify-content-center w-95 h-60">No variable names found</div>}
          placeholderForSearch={<SearchPlaceholder />}
          placeholderForSearchClassName="w-auto simple-table-spinner"
          filterProvider={filterProvider}
          withLocation
          isLimitEditable={true}
          withoutPagination={true}
        />
      </ModalBody>
    </CustomModal>
  );
}
