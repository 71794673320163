import React, { useEffect, useRef, useState } from "react";
import { Nav, NavItem, NavLink } from "reactstrap";
import classNames from "classnames";

export const REPORTS = [
  {
    label: "Users and Quizes",
    src: process.env.REACT_APP_USERS_QUIZES_REPORT_URL,
    aspectRatio: 1280 / 1230,
  },
  {
    label: "Samples and Products",
    src: process.env.REACT_APP_SAMPLES_PRODUCTS_REPORT_URL,
    aspectRatio: 1280 / 720,
  },
  {
    label: "Biomarkers and Foods",
    src: process.env.REACT_APP_BIOMARKERS_FOODS_REPORT_URL,
    aspectRatio: 1280 / 860,
  },
];

const ReportsTable = () => {
  const [activeTab, updateActiveTab] = useState(0);

  const [reports, setReports] = useState(
    REPORTS.map((report, index) => {
      return index === activeTab ? report : null;
    })
  );

  const [isLoading, setIsLoading] = useState(false);
  const [isNavDisabled, setIsNavDisabled] = useState(true);

  const ref = useRef(null);
  const [width, setWidth] = useState(null);

  useEffect(() => {
    setWidth(ref.current.offsetWidth);

    const handleWindowResize = () => {
      setWidth(ref.current.offsetWidth);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  useEffect(() => {
    let timerId;

    if (isLoading) {
      timerId = setTimeout(() => {
        setIsLoading(false);
        setIsNavDisabled(false);
      }, 1000);
    }

    return () => clearTimeout(timerId);
  }, [isLoading]);

  const handleNavClick = (index) => {
    if (activeTab === index) return;

    updateActiveTab(index);

    if (!reports[index]) {
      setIsNavDisabled(true);
      const newArray = [...reports];
      newArray[index] = REPORTS[index];
      setReports(newArray);
    }
  };

  return (
    <>
      <Nav tabs className="nav-tabs-custom nav-justified mt-1">
        {REPORTS.map((report, index) => (
          <NavItem key={`navItem${index}`}>
            <NavLink
              disabled={isNavDisabled}
              className={classNames(
                {
                  active: activeTab === index,
                },
                "cursor-pointer"
              )}
              onClick={() => handleNavClick(index)}
            >
              <span>{report.label}</span>
            </NavLink>
          </NavItem>
        ))}
      </Nav>
      <div className="d-flex justify-content-center mt-4" ref={ref}>
        {reports.map((report, index) => {
          return report && width ? (
            <iframe
              key={`table${index}`}
              title={report.label}
              width={width}
              height={width / report.aspectRatio}
              src={`${report.src}&language=en-GB`}
              allowFullScreen={false}
              style={{
                clipPath: "inset(0 0 60px 0)",
                display: activeTab === index ? "initial" : "none",
              }}
              onLoad={() => setIsLoading(true)}
            />
          ) : null;
        })}
      </div>
    </>
  );
};

export default ReportsTable;
