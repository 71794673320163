import React, { useMemo } from 'react';
import RulesDropdown from "./RuleDropdown";
import Button from "../../../../../base/components/Button";
import { BUTTON_COLORS } from "../../../../../base/components/Button/appearance";
import {
  MAX_RANGE_LENGTH
} from "./constants";
import { floatOnly, floatPositiveOnly } from "../../../../../base/helpers/string";
import RangeInputWithUnit from "./RangeInputWithUnit";
import { DEFAULT_GROUP_LOW, DEFAULT_GROUP_HIGH, REC_SECTION_IDS } from "../Recommendations/constants";
import { checkOnDefined } from "../fromValidation";
import { MIN_GROUP_LENGTH } from "../Recommendations/GroupDropdown";
import { DNA_RANGE_INPUTS, RANGE_INPUTS, SKIN_RANGE_INPUTS } from "../constants";
import joinClassNames from "../../../../../base/helpers/joinClassNames";
import ReactSwitch from "../../../../../base/components/Switch";

const COMMA_SEPARATOR = ", ";
const YEARS_OLD = " y.o.";

export const getFullNameForFilter = (filterValuesObject, allOptionsObject) => {
  const { sexes, ages, ethnicities, otherFeatures } = filterValuesObject;
  const { allSexTypes, allAgeTypes, allEthnicityTypes, allOtherFeatureTypes } = allOptionsObject;

  const isAllSexSelected = sexes?.length === allSexTypes?.length;
  const isAllAgeSelected = ages?.length === allAgeTypes?.length;
  const isAllEthnicitySelected = ethnicities?.length === allEthnicityTypes?.length;
  const isAllOtherSelected = otherFeatures?.length === allOtherFeatureTypes?.length;

  if(isAllSexSelected && isAllAgeSelected && isAllEthnicitySelected && isAllOtherSelected) {
    return "All, All, All, All";
  }

  if(isAllSexSelected && isAllAgeSelected && isAllEthnicitySelected && !otherFeatures.length) {
    return "All, All, All";
  }

  let fullFilterName = "";

  const arrayToJoin = [
    { currentValues: sexes, allValues: allSexTypes },
    { currentValues: ages, allValues: allAgeTypes, textAfterItem: YEARS_OLD },
    { currentValues: ethnicities, allValues: allEthnicityTypes },
    { currentValues: otherFeatures, allValues: allOtherFeatureTypes },
  ]

  arrayToJoin.forEach(({ currentValues, allValues, textAfterItem = "" }) => {
    if(currentValues?.length && currentValues.length !== allValues.length) {
      const currentValuesTextArray = currentValues.map((item) => item?.key);
      fullFilterName += (fullFilterName.length ? COMMA_SEPARATOR : "") +
        currentValuesTextArray.join(COMMA_SEPARATOR) + textAfterItem;
    }
  })
  return fullFilterName;
}

export default function Ranges({
  openFiltersModal,
  filters = [],
  setFieldValue,
  unit,
  selectedFilterIndex = 0,
  handleEditFilter,
  disabled,
  recommendations,
  keyForGroups,
  groups,
  isSkinCategory,
  isDNAmCategory,
  errors,
  category
}) {

  const isCurrentInputsDisabled = !filters?.[selectedFilterIndex]?.sexes?.length ||
    !filters?.[selectedFilterIndex]?.ages?.length ||
    !filters?.[selectedFilterIndex]?.ethnicities?.length;

  const showPercentageInput = filters?.[selectedFilterIndex]?.overrideSwitchStatus === true && (!isSkinCategory && !isDNAmCategory)

  const handleDeleteFilter = () => {
    setFieldValue("filters", filters.filter((_, index) => index !== selectedFilterIndex));
    setFieldValue("selectedFilterIndex", 0);
  }

  const onRangeChange = (value, inputName, defaultGroupsMap, idForGroup, groupName) => {
    if(!idForGroup) return;

    let newFilters = { ...filters[selectedFilterIndex], [inputName]: value }
    const currentGroupId = idForGroup;
    const currentGroupValues = groups[groupName];

    if(!value && currentGroupValues?.includes(currentGroupId)) {
      setFieldValue(
        `${keyForGroups}.${groupName}`,
        currentGroupValues.length <= MIN_GROUP_LENGTH ? [] : currentGroupValues.filter(id => id !== currentGroupId)
      )
      return;
    }

    if(!defaultGroupsMap) return;

    const canBeGroupedByDefault = defaultGroupsMap.every(({ rangesToApply, name }) => {
      return rangesToApply.some(rangeKey => checkOnDefined(newFilters[rangeKey])) &&
        !recommendations[name]?.filter(({ id }) => id)?.length
    })


    if(canBeGroupedByDefault && !currentGroupValues?.length) {
      const currentItem = defaultGroupsMap.find(({ idForGroup: defaultIdForGroup }) => defaultIdForGroup === idForGroup).name;
      const itemToCopy = defaultGroupsMap.find(({ idForGroup: defaultIdForGroup }) => defaultIdForGroup !== idForGroup).name;
      setFieldValue(`${keyForGroups}.${groupName}`, defaultGroupsMap.map(({ idForGroup }) => idForGroup))
      setFieldValue(`filters[${selectedFilterIndex}].recommendations.${currentItem}`, filters[selectedFilterIndex].recommendations[itemToCopy])
      setFieldValue(`filters[${selectedFilterIndex}].resultSummary.${currentItem}`, filters[selectedFilterIndex].resultSummary[itemToCopy])
    }
  }

  const updateResultSummary = (value, rangeToCopy, currentRangeName) => {
    if(!value || !currentRangeName || !rangeToCopy) return;
    const summaryToCopy = filters[selectedFilterIndex].resultSummary?.[rangeToCopy];

    if(summaryToCopy){
      setFieldValue(`filters[${selectedFilterIndex}].resultSummary.${currentRangeName}`, summaryToCopy)
    }
  }

  const arrayOfRanges = useMemo(() => {
    if(isSkinCategory){
      return SKIN_RANGE_INPUTS;
    }
    if(isDNAmCategory){
      return DNA_RANGE_INPUTS;
    }
    return RANGE_INPUTS
  }, [isSkinCategory, isDNAmCategory]);

  return (
    <section className="filter-container">
      {!isCurrentInputsDisabled && !isSkinCategory && !isDNAmCategory &&
        <RulesDropdown
          label="Filter"
          value={filters[selectedFilterIndex]?.name}
          onChange={(item, index) => {
            setFieldValue("selectedFilterIndex", index)
          }}
          options={filters}
          withEdit
          onEdit={handleEditFilter}
          withDelete
          onDelete={handleDeleteFilter}
          selectedFilterIndex={selectedFilterIndex}
        />
      }
      {!isSkinCategory && !isDNAmCategory && category !== undefined && (
        <div className="d-flex flex-row justify-content-between">
          <div className='d-flex flex-row align-items-center'>
            <div className="me-2">
              <label className="me-1">Override Scale</label>
              <ReactSwitch
                state={filters?.[selectedFilterIndex]?.overrideSwitchStatus}
                updateState={(status) => {
                  setFieldValue(`filters[${selectedFilterIndex}].overrideSwitchStatus`, status)
                }}
              />
            </div>
            <div>
              <label className="me-1">Floor value</label>
              <ReactSwitch state={filters?.[selectedFilterIndex]?.floorSwitchStatus} updateState={(status) => {
                setFieldValue(`filters[${selectedFilterIndex}].floorSwitchStatus`, status)
              }} />
            </div>
          </div>
          {!isSkinCategory && !isDNAmCategory &&
            <Button
              onClick={openFiltersModal}
              color={BUTTON_COLORS.primary}
              className="mb-2"
            >
              + Create filter
            </Button>
          }
        </div>
      )}
      <section className={joinClassNames(
        "filter-container--heading",
        (isSkinCategory || isDNAmCategory) && "py-2"
      )}>
        <label>Range</label>
        <label>Values</label>
      </section>

      <section className="filter-container--ranges mt-3">
        <label className="align-self-center mb-0 font-weight-normal">
          {isDNAmCategory && "Optimal"}
          {isSkinCategory && "Poor"}
          {!isSkinCategory && !isDNAmCategory && "Critical low"}
        </label>
        <div className='row'>
          <div className={showPercentageInput ? 'col-10' : 'col-12'}>
            <RangeInputWithUnit
              onChange={(event) => {
                const value = (isDNAmCategory ? floatOnly(event.target.value) : floatPositiveOnly(event.target.value)) || undefined;
                setFieldValue(`filters[${selectedFilterIndex}].criticalLow`, value)
                if (value === undefined) {
                  setFieldValue(`filters[${selectedFilterIndex}].criticalLowPercentage`, undefined);
                }
                if (isSkinCategory) {
                  updateResultSummary(value, "low", "criticalLow");
                  return;
                }
                onRangeChange(value, "criticalLow", DEFAULT_GROUP_LOW, REC_SECTION_IDS.criticalLow, "low")
              }}
              placeholder=""
              unit={isDNAmCategory ? "(required)" : unit}
              disabled={disabled}
              name={`filters[${selectedFilterIndex}].criticalLow`}
              withAdditionalSymbols={isDNAmCategory}
              additionalSymbol="≤"
              inputClassName={isDNAmCategory && "ps-4"}
            />
          </div>
          {showPercentageInput && (
            <div className='col-2'>
              <RangeInputWithUnit
                forceValidation={true}
                onChange={(event) => {
                  const value = floatPositiveOnly(event.target.value) || undefined;
                  if (filters?.[selectedFilterIndex].criticalLow !== undefined) {
                    setFieldValue(`filters[${selectedFilterIndex}].criticalLowPercentage`, value);
                  }
                }}
                unit={isDNAmCategory ? "(required)" : unit}
                disabled={disabled}
                withAdditionalSymbols={true}
                symbol={'%'}
                name={`filters[${selectedFilterIndex}].criticalLowPercentage`}
              />
              <div className="invalid-feedback d-inline fs-6">
                {errors.filters?.[selectedFilterIndex]?.criticalLowPercentage}
              </div>
            </div>
          )}
        </div>

        {arrayOfRanges.map(({
          itemKeys,
          label,
          placeholder,
          idForGroup,
          groupItems,
          groupName,
          copyResultSummary,
          currentRangeName,
        }) => {
          const [min, max] = itemKeys;
          const percentageInputName = `${label.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase())}Percentage`
          return (
            <React.Fragment key={label}>
              <label className="align-self-center mb-0 font-weight-normal">{label}</label>
              <section className="row">
                <div className={joinClassNames(showPercentageInput ? 'col-5 pe-0': 'col-6')}>
                  <RangeInputWithUnit
                    onChange={(event) => {
                      const value = floatPositiveOnly(event.target.value) || undefined;
                      setFieldValue(`filters[${selectedFilterIndex}].${min}`, value);
                      if (filters?.[selectedFilterIndex][max] === undefined && value === undefined) {
                        setFieldValue(`filters[${selectedFilterIndex}].${percentageInputName}`, undefined);
                      }
                      onRangeChange(value, min, groupItems, idForGroup, groupName);
                      if (isSkinCategory) {
                        updateResultSummary(value, copyResultSummary, currentRangeName);
                      }
                    }}
                    placeholder={placeholder}
                    unit={isDNAmCategory ? "(required)" : unit}
                    disabled={disabled}
                    withAdditionalSymbols={isDNAmCategory}
                    name={`filters[${selectedFilterIndex}].${min}`}
                  />
                </div>
                <label className='col-1 m-0 p-0 d-flex align-items-center justify-content-center'>-</label>
                <div className={joinClassNames(showPercentageInput ? 'col-4 ps-0': 'col-5')}>
                  <RangeInputWithUnit
                    onChange={(event) => {
                      const value = floatPositiveOnly(event.target.value) || undefined;
                      setFieldValue(`filters[${selectedFilterIndex}].${max}`, value);
                      if (filters?.[selectedFilterIndex][min] === undefined && value === undefined) {
                        setFieldValue(`filters[${selectedFilterIndex}].${percentageInputName}`, undefined);
                      }
                      onRangeChange(value, max, groupItems, idForGroup, groupName)
                      if (isSkinCategory) {
                        updateResultSummary(value, copyResultSummary, currentRangeName);
                      }
                    }}
                    placeholder={placeholder}
                    unit={isDNAmCategory ? "(required)" : unit}
                    disabled={disabled}
                    withAdditionalSymbols={isDNAmCategory}
                    name={`filters[${selectedFilterIndex}].${max}`}
                  />
                </div>
                {showPercentageInput && (
                  <div className='col-2'>
                    <RangeInputWithUnit
                      forceValidation={true}
                      onChange={(event) => {
                        const value = floatPositiveOnly(event.target.value) || undefined;
                        if (filters?.[selectedFilterIndex][min] !== undefined && filters?.[selectedFilterIndex][max] !== undefined) {
                          setFieldValue(`filters[${selectedFilterIndex}].${percentageInputName}`, value);
                        }
                      }}
                      placeholder={placeholder}
                      unit={isDNAmCategory ? "(required)" : unit}
                      disabled={disabled}
                      withAdditionalSymbols={true}
                      symbol={'%'}
                      name={`filters[${selectedFilterIndex}].${percentageInputName}`}
                    />
                    <div className="invalid-feedback d-inline fs-6">
                      {errors.filters?.[selectedFilterIndex]?.[percentageInputName]}
                    </div>
                  </div>
                )}
              </section>
            </React.Fragment>
          )
        })}

        <label className="align-self-center mb-0 font-weight-normal">
          {isSkinCategory ? "Optimal" : "Critical high"}
        </label>
        <section className="row">
          <div className={`${(!isSkinCategory && !isDNAmCategory && !filters?.[selectedFilterIndex]?.overrideSwitchStatus) ? 'col-6' : (filters?.[selectedFilterIndex]?.overrideSwitchStatus === true && !isSkinCategory && !isDNAmCategory) ? 'col-5 pe-0': 'col-12'}`}>
            <RangeInputWithUnit
              onChange={(event) => {
                const val = floatPositiveOnly(event.target.value) || undefined
                setFieldValue(`filters[${selectedFilterIndex}].criticalHigh`, val)
                if (!isSkinCategory && !isDNAmCategory) {
                  if (filters?.[selectedFilterIndex].criticalHighMax === undefined && val === undefined) {
                    setFieldValue(`filters[${selectedFilterIndex}].criticalHighPercentage`, undefined);
                  }
                } else {
                  if (val === undefined) {
                    setFieldValue(`filters[${selectedFilterIndex}].criticalHighPercentage`, undefined);
                  }
                }
                if (isSkinCategory) return;
                onRangeChange(floatPositiveOnly(event.target.value) || undefined, "criticalHigh", DEFAULT_GROUP_HIGH, REC_SECTION_IDS.criticalHigh, "high");
              }}
              placeholder=""
              maxLength={MAX_RANGE_LENGTH}
              unit={isDNAmCategory ? "(required)" : unit}
              disabled={disabled}
              name={`filters[${selectedFilterIndex}].criticalHigh`}
              withAdditionalSymbols={isDNAmCategory}
              additionalSymbol="≥"
              inputClassName={isDNAmCategory && "ps-4"}
            />
          </div>
          {(!isSkinCategory && !isDNAmCategory) && (
            <>
              <label className="col-1 d-flex align-items-center justify-content-center m-0">-</label>
              <div className={!showPercentageInput? 'col-5': 'col-4 ps-0'}>
                <RangeInputWithUnit
                  onChange={(event) => {
                    const val = floatPositiveOnly(event.target.value) || undefined
                    setFieldValue(`filters[${selectedFilterIndex}].criticalHighMax`, val)
                    if (filters?.[selectedFilterIndex].criticalHigh === undefined && val === undefined) {
                      setFieldValue(`filters[${selectedFilterIndex}].criticalHighPercentage`, undefined);
                    }
                    if (isSkinCategory) return;
                    onRangeChange(floatPositiveOnly(event.target.value) || undefined, "criticalHigh", DEFAULT_GROUP_HIGH, REC_SECTION_IDS.criticalHigh, "high");
                  }}
                  placeholder=""
                  maxLength={MAX_RANGE_LENGTH}
                  unit={isDNAmCategory ? "(required)" : unit}
                  disabled={disabled}
                  name={`filters[${selectedFilterIndex}].criticalHighMax`}
                  withAdditionalSymbols={isDNAmCategory}
                  additionalSymbol="≤"
                  inputClassName={isDNAmCategory && "ps-4"}
                />
              </div>
            </>
          )}
          {showPercentageInput && (
            <>
              <div className='col-2'>
                <RangeInputWithUnit
                  forceValidation={true}
                  onChange={(event) => {
                    const value = floatPositiveOnly(event.target.value) || undefined;
                    if (filters?.[selectedFilterIndex].criticalHighMax !== undefined) {
                      setFieldValue(`filters[${selectedFilterIndex}].criticalHighPercentage`, value);
                    }
                  }}
                  unit={isDNAmCategory ? "(required)" : unit}
                  disabled={disabled}
                  withAdditionalSymbols={true}
                  symbol={'%'}
                  name={`filters[${selectedFilterIndex}].criticalHighPercentage`}
                />
                <div className="invalid-feedback d-inline fs-6">
                  {errors.filters?.[selectedFilterIndex]?.criticalHighPercentage || errors.filters?.[selectedFilterIndex]?.criticalHighPercentage}
                </div>
              </div>
            </>
          )}
        </section>
      </section>
    </section>
  )
}