import React, { useEffect, useMemo } from 'react';
import RecommendationItem from "./RecommendationItem";
import { MAX_ALTERNATIVE_NAMES, MAX_SKIN_RECOMMENDATIONS } from "../constants";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import PlusButtonWithTooltip from "../../../../../base/components/PlusButtonWithTooltip";
import RecommendationsCollapse from "../components/RecommendationsCollapse";
import { GroupComponent } from "./GroupDropdown";

export default function RecommendationSection({
  name,
  label,
  recommendations = [],
  addNewRecommendation,
  changeRecommendationsOrder,
  deleteRecommendation,
  onSelect,
  idForGroup,
  currentGroup,
  keyForGroupChange,
  canBeGroupedWith,
  setFieldValue,
  withGroupOption,
  defaultLabel,
  onGroup,
  isSkinCategory,
  isDNAmCategory,
}) {
  const maxRecommendationsCount = useMemo(() => {
    if(isDNAmCategory) return MAX_SKIN_RECOMMENDATIONS;
    if(isSkinCategory) return MAX_SKIN_RECOMMENDATIONS;
    return MAX_ALTERNATIVE_NAMES;
  },[isSkinCategory, isDNAmCategory]);

  const isAddNewAvailable = recommendations?.length < maxRecommendationsCount;

  const onDragEnd = (result) => {
    if(!result.destination) {
      return;
    }
    changeRecommendationsOrder(result.source.index, result.destination.index, name)
  }

  useEffect(() => {
    if(!recommendations?.length) {
      addNewRecommendation();
    }
  }, [recommendations.length]);

  return (
    <RecommendationsCollapse
      label={label}
      withGroupOption={withGroupOption}
      GroupComponent={<GroupComponent
        idForGroup={idForGroup}
        currentGroup={currentGroup}
        keyForGroupChange={keyForGroupChange}
        canBeGroupedWith={canBeGroupedWith}
        setFieldValue={setFieldValue}
        defaultLabel={defaultLabel}
        onGroup={onGroup}
      />}
    >
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="recommendationsDnd">
          {(provided) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {recommendations.map((recommendation = {}, index) => (
                <RecommendationItem
                  key={index}
                  sectionRecommendations={recommendations}
                  currentRecommendationIndex={index}
                  recommendation={recommendation}
                  canBeDragged={recommendations.length > 1}
                  canBeDeleted={!(recommendations.length === 1 && !recommendation.id)}
                  onDelete={deleteRecommendation}
                  onSelect={onSelect}
                  value={recommendation?.title}
                  isSkinCategory={isSkinCategory}
                  isDNAmCategory={isDNAmCategory}
                />
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <PlusButtonWithTooltip
        id={"addRecommendation" + name}
        disabled={!isAddNewAvailable}
        onClick={addNewRecommendation}
        buttonText="Add recommendation"
        tooltipText={`Max. ${maxRecommendationsCount} recommendations can be added.`}
        containerClassName="mt-3"
      />
    </RecommendationsCollapse>
  )
}