import React, { useCallback, useEffect, useState } from "react";
import {
    CustomModal,
    ModalBody,
    ModalHeader
} from "../../../../base/components/CustomModal";
import Table from "../../../../base/components/Table";
import { useService } from "../../../../base/hooks/useService";
import UserResultsService from "../../../../services/UserResults";
import { useLoading } from "../../../../base/hooks/useLoading";
import ToasterService from "../../../../services/ToastService";
const formatDate = (s = '') => s.split('T')[0].split('-').reverse().join('/');

export default function CsvViewer({
                                      isOpen,
                                      updateIsOpen,
                                      afterOnClose,
                                      details
                                  }) {
    /**
     * @type {UserResultsService}
     */
    const userResultsService = useService(UserResultsService);
    /**
     * @type {ToasterService}
     */
    const toastService = useService(ToasterService);

    const { id } = details;

    const [meta, setMeta] = useState([]);
    const [foods, setFoods] = useState([]);
    const [extra, setExtra] = useState();


    const [isLoading, { registerPromise }] = useLoading(false);

    const onClose = () => {
        updateIsOpen(false);
        afterOnClose();
    };

    const getData = useCallback(() => {
        registerPromise(userResultsService.getCsvData(id))
            .then((data) => {
                setMeta(data.meta);
                setFoods(data.foodsData);
                setExtra(data.extra);
            })
            .catch(() => {
                onClose();
            });
    }, [details]);

    useEffect(() => {
        if (isOpen === true) {
            getData();
        }
    }, [isOpen]);

    return (
        <CustomModal scrollable={false} isOpen={isOpen} className="csvViewerModal foodIntoleranceModal" toggle={onClose}>
            <ModalHeader
                onClose={onClose}
                sectionClassName="border-none"
            >
                <p className="text-dark mb-1">View CSV Result</p>
                <div className="d-flex">
                    <p className="uploaded-tag mb-0 me-1">Uploaded:</p>
                    <p className="uploaded-val mb-0">{formatDate(extra?.updatedAt)}</p>
                </div>
            </ModalHeader>
            <ModalBody className="h-82 d-flex flex-column ">
                <Table
                    tableClassName={'meta-table mb-4 custom-scrollbar'}
                    columns={[
                        {
                            Header: 'Panel',
                            accessor: 'Panel',
                            width: 250
                        },
                        {
                            Header: 'FoodPrint 200+',
                            accessor: 'FoodPrint 200+',
                            width: 240
                        }
                    ]}
                    data={meta}
                    loading={isLoading}
                    commonPlaceholder="No results for now."
                    placeholderForSearch={"No results for now."}
                    withoutPagination={true}
                />

                <Table
                    tableClassName={'food-table custom-scrollbar'}
                    columns={[
                        {
                            Header: "#",
                            width: 10,
                            className: 'text-center align-middle',
                            Cell: (cellProps) => {
                                return <label className="mb-0">{cellProps.row.index + 1}</label>;
                            }
                        },
                        {
                            Header: 'FOOD (English)',
                            accessor: 'FOOD (English)',
                        },
                        {
                            Header: 'FOOD (Translated)',
                            accessor: 'FOOD (Translated)'
                        },
                        {
                            Header: 'GROUP (English)',
                            accessor: 'GROUP (English)'
                        },
                        {
                            Header: 'GROUP (Translated)',
                            accessor: 'GROUP (Translated)'
                        },
                        {
                            Header: 'RESULT',
                            accessor: 'RESULT'
                        },
                        {
                            Header: 'INTERPRETATION',
                            accessor: 'INTERPRETATION'
                        }
                    ]}
                    data={foods}
                    loading={isLoading}
                    commonPlaceholder="No results for now."
                    placeholderForSearch={"No results for now."}
                    withoutPagination={true}
                    headerClassName="position-sticky top-0"
                />

            </ModalBody>
        </CustomModal>
    );
}
