import React from 'react';
import { Container } from "reactstrap";
import { Route, Routes } from "react-router-dom";

import { PROGRAMS_GROUP_LINKS, PROGRAMS_GROUP_ROUTES } from "./config";
import List from "./List";
import CreateEditProgram from "./List/CreateEditProgram";

export default function Programs() {
    return (
        <Container fluid className="content">
            <Routes>

                <Route
                    path={PROGRAMS_GROUP_ROUTES.LIST}
                    element={<List />}
                />
                <Route
                    path={PROGRAMS_GROUP_ROUTES.CREATE_EDIT}
                    element={<CreateEditProgram />}
                />
            </Routes>
        </Container>
    );
}