import yup from "../../../../validation/yup";
import { yupToFormErrors } from "formik";
import { RANGES_PER_RANGE_NAME } from "./constants";
import {
  CRITICAL_HIGH_RANGE_KEYS,
  CRITICAL_LOW_RANGE_KEYS, DNAm_CATEGORY_NAME,
  HIGH_RANGE_KEYS,
  LOW_RANGE_KEYS,
  MAX_BIOMARKERS_LABEL_NAME_LENGTH,
  MAX_BIOMARKERS_NAME_LENGTH,
  MAX_BIOMARKERS_SHORT_NAME_LENGTH, MAX_RICH_TEXT_LENGTH,
  MIN_STRING_LENGTH, OPTIMAL_RANGE_KEYS, SKIN_CATEGORY_NAME, SUB_OPTIMAL_RANGE_KEYS, SUPRA_OPTIMAL_RANGE_KEYS,
  RangeList
} from "./constants";

export const MIN_FILTERS_ARRAY_LENGTH = 1;

export const checkOnDefined = (value) => {
  return !!value || value === 0;
}

const ARRAY_OF_SKIN_AND_DNAm = [SKIN_CATEGORY_NAME, DNAm_CATEGORY_NAME];

const checkOfRangeValue = (context, rangeKeys) => {
  return rangeKeys.some(rangeName => checkOnDefined(context.from[1]?.value[rangeName]))
}

const resultSummaryCheck = (rangeNames) => {
  return ["Summary is required", "Result summary is required", (value, context) => {
    if (value === '' || value === undefined) {
      return true
    } else {
      return !(!value && checkOfRangeValue(context, rangeNames))
    }
  }]
}

const optimalCheck = () => {
  return ["is-required", " ", (value, context) => {
    if (ARRAY_OF_SKIN_AND_DNAm.includes(context.from[1]?.value.category)) {
      return true
    }
    return !!checkOnDefined(value)
  }]
}

const highCheck = () => {
  return ["is-required", " ", (value, context) => {
    if (context.from[1]?.value.category !== DNAm_CATEGORY_NAME) {
      return true
    }
    return !!checkOnDefined(value)
  }]
}

const lowCheck = () => {
  return ["is-required", " ", (value, context) => {
    if (context.from[1]?.value.category !== DNAm_CATEGORY_NAME) {
      return true
    }
    return !!checkOnDefined(value)
  }]
}

const filterCheck = () => {
  return ["is-required", " ", (value, context) => {
    if (ARRAY_OF_SKIN_AND_DNAm.includes(context.from[1]?.value.category)) {
      return true
    }
    return value?.length
  }]
}

const criticalCheck = () => {
  return ["is-required", " ", (value, context) => {
    if (value === '' || value === undefined || value === null) {
      return true
    } else if (!ARRAY_OF_SKIN_AND_DNAm.includes(context.from[1]?.value.category)) {
      return true
    }
    return !!checkOnDefined(value)
  }]
}

const unitCheck = () => {
  return ["is-required", "Unit is required", (value, context) => {
    if (ARRAY_OF_SKIN_AND_DNAm.includes(context.parent.category)) {
      return true
    }
    return !!checkOnDefined(value)
  }]
}

const hautMetricCheck = () => {
  return ["is-required", "Corresponding Haut ai metric is required", (value, context) => {
    if (context.parent.category !== SKIN_CATEGORY_NAME) {
      return true
    }
    return !!checkOnDefined(value)
  }]
}

const customRangesCheck = (valueToFind) => {
  return ["is-required", " ", (value, context) => {
    if (context.from[1]?.value.category === SKIN_CATEGORY_NAME) {
      return true
    }
    if (!checkOnDefined(context.from[0]?.value[valueToFind])) {
      return true
    }
    return !!checkOnDefined(value)
  }]
}

const MAX_LENGTH_ERROR = "Maximum number is 10 000 symbols.";

export const CustomRangeValidationRuleMap = {
  optimalMax: optimalCheck,
  criticalHighMax: criticalCheck,
  lowMax: () => customRangesCheck("lowMin"),
  subOptimalMax: () => customRangesCheck("subOptimalMin"),
  highMax: highCheck
}

export const filterSchema = yup.object().shape({
  sexes: yup.array().test(...filterCheck()),
  ages: yup.array().test(...filterCheck()),
  //ethnicities: yup.array().test(...filterCheck()), - Comment Ethnicity validation as per task OD-3022
  ethnicities: yup.array(), 
  optimalMin: yup.string().nullable().test(...optimalCheck()),
  optimalMax: yup.string().nullable().test(...optimalCheck()),
  criticalHigh: yup.string().nullable().test(...criticalCheck()),
  criticalHighMax: yup.string().nullable().test(...criticalCheck()),
  criticalLow: yup.string().nullable().test(...lowCheck()),
  lowMax: yup.string().nullable().test(...customRangesCheck("lowMin")),
  subOptimalMax: yup.string().nullable().test(...customRangesCheck("subOptimalMin")),
  supraOptimalMin: yup.string().nullable().test(...customRangesCheck("supraOptimalMax")),
  highMin: yup.string().nullable().test(...highCheck()).test(...customRangesCheck("highMax")),
  highMax: yup.string().nullable().test(...highCheck()),
  summary: yup.string().nullable().max(MAX_RICH_TEXT_LENGTH, MAX_LENGTH_ERROR),
  resultSummary: yup.object().shape({
    criticalLow: yup.string().nullable().max(MAX_RICH_TEXT_LENGTH, MAX_LENGTH_ERROR).test(...resultSummaryCheck(CRITICAL_LOW_RANGE_KEYS)),
    low: yup.string().nullable().max(MAX_RICH_TEXT_LENGTH, MAX_LENGTH_ERROR).test(...resultSummaryCheck(LOW_RANGE_KEYS)),
    subOptimal: yup.string().nullable().max(MAX_RICH_TEXT_LENGTH, MAX_LENGTH_ERROR).test(...resultSummaryCheck(SUB_OPTIMAL_RANGE_KEYS)),
    optimal: yup.string().nullable().max(MAX_RICH_TEXT_LENGTH, MAX_LENGTH_ERROR).test(...resultSummaryCheck(OPTIMAL_RANGE_KEYS)),
    supraOptimal: yup.string().nullable().max(MAX_RICH_TEXT_LENGTH, MAX_LENGTH_ERROR).test(...resultSummaryCheck(SUPRA_OPTIMAL_RANGE_KEYS)),
    high: yup.string().nullable().max(MAX_RICH_TEXT_LENGTH, MAX_LENGTH_ERROR).test(...resultSummaryCheck(HIGH_RANGE_KEYS)),
    criticalHigh: yup.string().nullable().max(MAX_RICH_TEXT_LENGTH, MAX_LENGTH_ERROR).test(...resultSummaryCheck(CRITICAL_HIGH_RANGE_KEYS))
  }),
  whatIsIt: yup.string().nullable().max(MAX_RICH_TEXT_LENGTH, MAX_LENGTH_ERROR),
  whatCanYouDo: yup.string().nullable().max(MAX_RICH_TEXT_LENGTH, MAX_LENGTH_ERROR),
  whatAreTheRisks: yup.object().shape({
    low: yup.string().nullable().max(MAX_RICH_TEXT_LENGTH, MAX_LENGTH_ERROR),
    high: yup.string().nullable().max(MAX_RICH_TEXT_LENGTH, MAX_LENGTH_ERROR)
  }),
  whatAreTheCauses: yup.object().shape({
    low: yup.string().nullable().max(MAX_RICH_TEXT_LENGTH, MAX_LENGTH_ERROR),
    high: yup.string().nullable().max(MAX_RICH_TEXT_LENGTH, MAX_LENGTH_ERROR),
    bulletList: yup.array().of(yup.object().shape({
      content: yup.string().min(MIN_STRING_LENGTH).required("Link title is required"),
      type: yup.number(),
      studyLinks: yup.array()
    }))
  }),
  interactions: yup.array().of(yup.object().shape({
    name: yup.string().required(),
  }))
})

export const getEndpointType = ({ category }) => {
  if (category === SKIN_CATEGORY_NAME) {
    return "skin"
  }

  if (category === DNAm_CATEGORY_NAME) {
    return "dna-age"
  }

  return "blood"
};

export const buildDynamicValidation = (values) => {
  let lastRangeUsed = undefined
  for (let i = 0; i < RangeList.length; i++) {
    const [minRange] = RangeList[i];

    const filter = values.filters[values.filters.length - 1]
    if (filter && minRange in filter && filter[minRange] !== null) {
      lastRangeUsed = RangeList[i];
    }
  }

  let updatedSchema = filterSchema
  if (lastRangeUsed && getEndpointType(values) === "blood") {
    const rule = yup.string().required().test(...optimalCheck())
    if (CustomRangeValidationRuleMap[lastRangeUsed[1]]) {
      rule.test(...CustomRangeValidationRuleMap[lastRangeUsed[1]]())
    }
    updatedSchema = filterSchema.shape({ [lastRangeUsed[1]]: rule })
  }

  let validationSchema = yup.object().shape({
    name: yup.string()
      .trim()
      .min(MIN_STRING_LENGTH)
      .max(MAX_BIOMARKERS_NAME_LENGTH)
      .required(),
    label: yup.string()
      .trim()
      .min(MIN_STRING_LENGTH)
      .max(MAX_BIOMARKERS_LABEL_NAME_LENGTH)
      .required(),
    shortName: yup.string()
      .trim()
      .min(MIN_STRING_LENGTH)
      .max(MAX_BIOMARKERS_SHORT_NAME_LENGTH)
      .required(),
    category: yup.string().required(),
    unit: yup.string().test(...unitCheck()),
    hautAiMetricType: yup.string().nullable().test(...hautMetricCheck()),
  });

  validationSchema = validationSchema.shape({
    filters: yup.array().of(updatedSchema).min(MIN_FILTERS_ARRAY_LENGTH)
      .test({
        name: "totalPercentage",
        test: function (filters) {
          for (let idx = 0; idx < filters.length; idx++) {
            const filter = filters[idx];

            if (this.parent.category === SKIN_CATEGORY_NAME || this.parent.category === DNAm_CATEGORY_NAME || !filter.overrideSwitchStatus) {
              return true
            }

            for (let i = 0; i < RANGES_PER_RANGE_NAME.length; i++) {
              const item = RANGES_PER_RANGE_NAME[i];
              const percentage = filter[`${item.rangeName}Percentage`];

              const min = filter[item.ranges[0]]
              const max = filter[item.ranges[1]]

              if (item.ranges[1]) {
                if (min && max) {
                  if (!percentage) {
                    return this.createError({
                      path: `filters[${idx}].${item.rangeName}Percentage`,
                      message: "Required",
                    })
                  }
                }
              } else {
                if (min) {
                  if (!percentage) {
                    return this.createError({
                      path: `filters[${idx}].${item.rangeName}Percentage`,
                      message: "Required",
                    })
                  }
                }
              }
            }

            const valuesToEvaluate = ["criticalLowPercentage", "lowPercentage", "subOptimalPercentage", "optimalPercentage", "supraOptimalPercentage", "highPercentage", "criticalHighPercentage"].filter(field => filter[field] !== undefined && filter[field] !== null && filter[field] !== '' && typeof Number.parseFloat(filter[field]) === 'number');

            for (let i = 0; i < valuesToEvaluate.length; i++) {
              const value = filter[valuesToEvaluate[i]]
              if (value < 10) {
                return this.createError({
                  path: `filters[${idx}].${valuesToEvaluate[i]}`,
                  message: "Min value is 10",
                })
              }
            }

            const total = valuesToEvaluate.reduce((total, next) => total + Number.parseFloat(filter[next] || '0'), 0)
            if (total !== 100) {
              return this.createError({
                path: `filters[${idx}].totalPercentage`,
                message: "Total should be 100%",
              })
            }
          }

          return true
        }
      })
  })

  return validationSchema.validate(values).then(() => ({})).catch(err => {
    const errMap = yupToFormErrors(err)
    return errMap
  })
}
