import React, { useCallback, useEffect, useState } from 'react'
import { useService } from "../../hooks/useService";
import NotificationsService from "../../../services/NotificationsService";
import { useLoading } from "../../hooks/useLoading";
import { Dropdown, DropdownMenu, DropdownToggle, Spinner } from "reactstrap";
import Icon from "../Icon";
import {
  DATE_FULL_FORMAT_WITH_TIME,
  formatISODateWithZoneFromString
} from "../../helpers/date";
import Button from "../Button";
import { BUTTON_COLORS } from "../Button/appearance";
import { useNavigate } from "react-router-dom";
import { CUSTOMER_RESULTS_LINKS } from "../../../groups/app/CustomerResults/config";
import { useNotifications, useUpdateNotifications } from "../../context/notifications";
import ToasterService, { DEFAULT_TOAST_OPTIONS } from "../../../services/ToastService";

const MAX_NOTIFICATIONS_LIMIT = 5;
const MAX_COUNTER = 99;
const HALF_AN_HOUR = 1000 * 60 * 30
const NOTIFICATION_TYPES = {
  ERROR: 1,
  FILE: 2,
};

export default function NotificationsDropdown() {
  /**
   * @type {NotificationsService}
   */
  const notificationsService = useService(NotificationsService)
  /**
   * @type {ToasterService}
   */
  const toastService = useService(ToasterService);

  const [isOpen, updateIsOpen] = useState(false);
  const [notificationsList, updateNotificationsList] = useState([]);
  const [notificationsCounter, updateNotificationsCounter] = useState(0);

  const { shouldUpdate } = useNotifications();
  const updateNotifications = useUpdateNotifications();

  const navigate = useNavigate();

  const [isLoading, { registerPromise }] = useLoading(true);

  const redirectToCustomerResults = (search = "") => {
    navigate({
      pathname: CUSTOMER_RESULTS_LINKS.LIST,
      search,
    })
  }

  const redirectToCustomerDetails = (id) => {
    navigate({
      pathname: CUSTOMER_RESULTS_LINKS.DETAILS.replace(':id', id)
    });
  }

  const getNotifications = useCallback((callback = () => {
  }) => {
    registerPromise(
      notificationsService.getNotifications({
        offset: 0,
        limit: MAX_NOTIFICATIONS_LIMIT,
        isResolved: false,
      })
        .then(({ data, pagination }) => {
          callback(pagination.totalCount, data)
          updateNotificationsList(data)
          updateNotificationsCounter(pagination.totalCount)
        })
        .finally(() => {
          updateNotifications({ shouldUpdate: false })
        })
    )
  }, [notificationsService])

  useEffect(() => {
    getNotifications()
  }, [getNotifications]);

  useEffect(() => {
    if (!shouldUpdate) return;
    getNotifications();
  }, [shouldUpdate]);

  useEffect(() => {
    const notificationsPollingInterval = setInterval(() => {
      getNotifications((newCount, newData) => {
        if (newCount > notificationsCounter) {
          toastService.error(<section>
              <p className="text-white mb-0 font-weight-semibold">Error Sample ID {newData[0]?.sampleCode}</p>
              <p className="line-clamp-1 text-white mb-0 mt-1 w-100">
                {newData[0]?.isMultipleError ? "Multiple errors in the file" : newData[0]?.message}
              </p>
            </section>,
            { ...DEFAULT_TOAST_OPTIONS, className: "notification-error-toast-container" }
          )
        }
      });
    }, HALF_AN_HOUR);

    return () => {
      clearInterval(notificationsPollingInterval);
    }
  }, [notificationsCounter]);

  return (
    <Dropdown
      isOpen={isOpen}
      toggle={() => updateIsOpen(prevState => !prevState)}
      className="d-inline-block me-3"
    >
      <DropdownToggle
        className="btn header-item no-outline no-border pe-0"
        id="page-header-user-dropdown"
        tag="button"
      >
        <section
          className="pointer-events-none position-relative h-100 w-100 d-flex justify-content-center align-items-center">
          <Icon icon="notifications" className=""/>
          {!!notificationsCounter &&
            <div className="notification-badge">
              {notificationsCounter > MAX_COUNTER ? `${MAX_COUNTER}+` : notificationsCounter}
            </div>
          }
        </section>
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu-end notifications-dropdown pb-0">
        <section className="p-3 notifications-dropdown__title">
          Notifications
        </section>

        {notificationsList.length && !isLoading ?
          <section className="notifications-dropdown--list custom-scrollbar me-2">
            {notificationsList.map(({ id, sampleCode, createdAt, message, type, fileUserId }) => {
              return (
                <section
                  className="notifications-dropdown--list__item"
                  key={id}
                  onClick={() => {
                    if (type === NOTIFICATION_TYPES.FILE) {
                      redirectToCustomerDetails(fileUserId);
                    } else {
                      redirectToCustomerResults(`?search=${sampleCode}`)
                    }
                    updateIsOpen(false)
                  }}
                >
                  {type === NOTIFICATION_TYPES.FILE ?
                    <>
                      <Icon icon="fileNotification"/>

                      <section className="d-flex flex-column">
                        <label className="notifications-dropdown--list__item__title">Uploaded PDF result</label>
                        <p className="notifications-dropdown--list__item__message">{message}</p>
                        <label className="mt-1 notifications-dropdown--list__item__time">
                          <Icon icon="clocks" className="me-1"/>
                          {formatISODateWithZoneFromString(createdAt, DATE_FULL_FORMAT_WITH_TIME)}
                        </label>
                      </section>
                    </> : <>
                      <Icon icon="errorNotification"/>

                      <section className="d-flex flex-column">
                        <label className="notifications-dropdown--list__item__title">Error Sample
                          ID {sampleCode}</label>
                        <p className="notifications-dropdown--list__item__message">{message}</p>
                        <label className="mt-1 notifications-dropdown--list__item__time">
                          <Icon icon="clocks" className="me-1"/>
                          {formatISODateWithZoneFromString(createdAt, DATE_FULL_FORMAT_WITH_TIME)}
                        </label>
                      </section>
                    </>
                  }
                </section>
              )
            })}
          </section>
          :
          <section className="notifications-dropdown__placeholder">
            {isLoading ? <Spinner color="info"/> : "No notifications for now"}
          </section>
        }

        {!!notificationsList.length && !isLoading &&
          <Button
            className="w-100 notifications-dropdown__view-all"
            color={BUTTON_COLORS.link}
            onClick={() => {
              redirectToCustomerResults("?status=3")
              updateIsOpen(false);
            }}
          >
            View all
          </Button>
        }
      </DropdownMenu>
    </Dropdown>
  )
}
