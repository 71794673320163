import React from 'react';

export default function BiomarkerFilters({ row }){
  return (
    <div className="d-flex flex-row align-items-center flex-wrap biomarker-table-filters">
      {row.filters.map(({ name, id }) => {
        return (
          <div key={id} className="biomarker-table-filter">
            {name}
          </div>
        )
      })}
    </div>
  )
}