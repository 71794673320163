import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useQueryString } from "../../../../../base/hooks/useQueryString";
import { useEffect, useState } from "react";
import ProgramsService from "../../../../../services/ProgramsService";
import { useService } from "../../../../../base/hooks/useService";
import BaseLayoutWithCard from "../../../../../base/components/BaseLayoutWithCard";
import { PROGRAMS_GROUP_LINKS } from "../../config";
import { Formik } from "formik";
import joinClassNames from "../../../../../base/helpers/joinClassNames";
import Title from "../../../Biomarkers/Details/Title";
import { FormAvatar } from "../../../../../base/components/Dropzone/Dropzone";

import useUploadImages from "../../../../../base/components/Dropzone/useUploadImages";
import ToastService from "../../../../../services/ToastService";
import { initialValues, validateFile, validationSchema, energyEquationOptions } from "./form";
import FormikInput from "../../../../../base/components/FormikInput";
import { MAX_RULE_NAME_LENGTH } from "../../../Biomarkers/CreateEdit/constants";
import FormikReactSelect from "../../../../../base/components/FormikReactSelect";
import FoodIntoleranceDropdown from "../../../../../base/components/FoodIntoleranceDropdown/FoodIntoleranceDropdown";
import RichTextEditor from "../../../../../base/components/RichTextEditor";
import { MAX_PROGRAM_LONG_DESCRIPTION, MAX_PROGRAM_SHORT_DESCRIPTION } from "./constants";
import Button from "../../../../../base/components/Button";
import { BUTTON_COLORS } from "../../../../../base/components/Button/appearance";
import Switch from "../../../../../base/components/Switch";

export default function CreateEditProgram() {
    /**
     * @type {ProgramsService}
     */
    const programsService = useService(ProgramsService);
    /**
     * @type {ProgramsService}
     */
    const toastService = useService(ToastService);
    const uploadImage = useUploadImages();
    const { search: locationSearch } = useLocation();
    const navigate = useNavigate();

    const [isSubmitting, updateIsSubmitting] = useState(false);
    const [program, setProgram] = useState(null);

    const {
        params: {
            copyProgramId,
            editProgramId
        }
    } = useQueryString(locationSearch);

    const afterSuccess = () => {
        toastService.success("Program has been successfully saved");
        navigate(PROGRAMS_GROUP_LINKS.LIST);
        updateIsSubmitting(false);
    };

    const createProgram = ({ file, restrictions, ...otherValues }) => {
        updateIsSubmitting(true);
        const fileId = file[0]?.id;

        if (fileId) {
            programsService.createProgram({
                ...otherValues,
                restrictions: restrictions.map(restriction => restriction._id),
                fileId
            }).then(afterSuccess).finally(() => updateIsSubmitting(false));
            return;
        }

        uploadImage(file[0].file).then(({ file }) => {
            return programsService.createProgram({
                ...otherValues,
                restrictions: restrictions.map(restriction => restriction._id),
                fileId: file.id
            });
        }).then(afterSuccess).finally(() => updateIsSubmitting(false));
    };

    useEffect(() => {
        if (copyProgramId || editProgramId) {
            programsService.getProgramById(copyProgramId || editProgramId)
                .then(({ data }) => setProgram(data));
        }
    }, [copyProgramId, editProgramId]);

    const breadcrumbs = {
        title: editProgramId ? "Edit program" : "Create program",
        breadcrumbItems: [
            { title: "Programs", link: PROGRAMS_GROUP_LINKS.BASE },
            { title: editProgramId ? "Edit program" : "Create program" }
        ]
    };

    return <BaseLayoutWithCard breadcrumbs={breadcrumbs}>
        <Formik
            initialValues={program || initialValues}
            validationSchema={validationSchema}
            validateOnBlur
            onSubmit={createProgram}
            enableReinitialize
        >
            {({ errors, handleSubmit, values, setFieldValue }) => {

                return <form className={joinClassNames("form-horizontal p-2", isSubmitting && "pointer-events-none")}
                             onSubmit={handleSubmit}>
                    <div className="d-flex justify-content-between align-items-center mb-4">
                        <Title
                            title="General information"
                        />

                        <label className="d-flex align-items-center gap-1">
                            Activate program
                            <Switch state={values.isActive}
                                    updateState={() => setFieldValue('isActive', !values.isActive)}/>
                        </label>
                    </div>
                    <section className="w-50">


                        <FormAvatar validateImage={validateFile}/>

                        <FormikInput
                            placeholder="Please enter a full program’s name (required)"
                            name="fullName"
                            maxLength={MAX_RULE_NAME_LENGTH}
                            label="Full name"
                            containerClassName="mt-3"
                        />

                        <FormikInput
                            placeholder="Please enter a program’s author (required)"
                            name="author"
                            maxLength={MAX_RULE_NAME_LENGTH}
                            label="Author"
                            containerClassName="mt-3"
                        />

                        <FormikReactSelect
                            name="energyEquation"
                            options={energyEquationOptions}
                            label="Energy and nutrient equation"
                            setFieldValue={setFieldValue}
                            containerClassName="mt-3"
                        />

                        <section className="mt-3 w-100">
                            <label className="w-100">
                                <span>Restrictions</span>
                            </label>
                            <FoodIntoleranceDropdown
                                placeholder="Select restrictions (required)"
                                value={values.restrictions}
                                onChange={(value) =>
                                    setFieldValue("restrictions", value)
                                }
                            />
                        </section>

                        <Title
                            title="About program"
                            className="mt-5"
                        />
                    </section>

                    <RichTextEditor
                        value={values.shortDescription}
                        onChange={(text) => {
                            setFieldValue('shortDescription', text);
                        }}
                        maxLength={MAX_PROGRAM_SHORT_DESCRIPTION}
                        name="shortDescription"
                        label="Short Description"
                        description="Enter short information about the program."
                        placeholder="Enter short information about the program..."
                    />

                    <RichTextEditor
                        value={values.longDescription}
                        onChange={(text) => {
                            setFieldValue('longDescription', text);
                        }}
                        maxLength={MAX_PROGRAM_LONG_DESCRIPTION}
                        name="longDescription"
                        label="Long Description"
                        description="Enter long information about the program."
                        placeholder="Enter long information about the program..."
                    />

                    <div className="d-flex justify-content-end mt-5">
                        {<Button
                            color={BUTTON_COLORS.primary}
                            type="submit"
                            disabled={!!Object.keys(errors).length || isSubmitting}
                        >
                            Save program
                        </Button>}
                    </div>
                </form>;
            }}
        </Formik>
    </BaseLayoutWithCard>;
}