import TableSearch from "../../../../../base/components/Table/tableSearch";
import React from "react";
import Button from "../../../../../base/components/Button";
import { useLocationQuery } from "../../../../../base/hooks/useQueryString";
import { useHighlight } from "../../../../../base/hooks/useHighlight";
import { BUTTON_COLORS } from "../../../../../base/components/Button/appearance";
import { formatISODate } from "../../../../../base/helpers/date";
import joinClassNames from "../../../../../base/helpers/joinClassNames";
import Icon from "../../../../../base/components/Icon";

export const PREDEFINED_ACTIONS = {
    DEACTIVATE: 'deactivate',
    ACTIVATE: 'activate',
    COPY: 'copy',
};

export const SearchPlaceholder = () => (
    <>
        No lab results found.
    </>
);

export const NoProgramPlaceholder = () => (
    <div className="text-center">
        No programs for now.<br/>
        Click the “Create program” option to add a new one.
    </div>
);
export const TableHeader = ({ searchProvider }) => {
    return (
        <section className="d-flex w-100 pt-1 my-3 align-items-center justify-content-between">
            <TableSearch
                className="biomarkers-search"
                search={searchProvider?.getValue()}
                onSearch={searchProvider.setValue}
                placeholder="Search program"
            />


            <Button color={BUTTON_COLORS.primary} className="d-flex align-items-center">
                <i className="bx bx-plus me-2"/>
                Create program
            </Button>
        </section>
    );
};

export const columns = [
    {
        Header: "#",
        width: 35,
        Cell: (cellProps) => {
            const { params: { offset = 0 } } = useLocationQuery();
            return <label className="mb-0">{cellProps.row.index + 1 + (offset ?? 0)}</label>;
        }
    },
    {
        Header: "Name",
        accessor: "fullName",
        width: 410,
        canSort: true,
        Cell: ({ value }) => {
            const { params } = useLocationQuery();
            const { decorateText } = useHighlight(params.search);

            return (
                <section className="w-fit-content d-flex align-items-center">
                    <div {...decorateText(value)} className="text-truncate mb-0" style={{ maxWidth: "33vw" }}/>
                </section>
            );
        }
    },
    {
        Header: "Author",
        accessor: "author",
        width: 410,
        canSort: true,
        Cell: ({ value }) => <span>{value}</span>
    },
    {
        Header: "Created on",
        accessor: "createdAt",
        width: 410,
        canSort: true,
        Cell: ({ value }) => <section className="w-fit-content d-flex align-items-center">
            {formatISODate(value)}
        </section>
    },
    {
        Header: "Active",
        accessor: "isActive",
        width: 410,
        canSort: true,
        Cell: ({ value }) => <div className={joinClassNames(
            value ? "active-pill" : "inactivated-pill",
            "w-fit-content"
        )}>
            {value ? "Inactive" : "Active"}
        </div>
    },
    {
        Header: "Actions",
        Cell: ({
                   row: {
                       original: { id, isActive, ...rest }
                   },
                   actions
               }) => {

            const handleSelect = (type) => {
                actions?.[type] && actions[type]({ id, isActive, ...rest });
            };

            return <section className="d-flex justify-content-between align-items-center">
                    <Icon icon="eye"
                          width={20}
                          height={20}
                          className="me-4 cursor-pointer impacts-section__view"
                          onClick={(event) => {
                              event.stopPropagation();

                          }}
                    />
                    <Icon
                        icon={!isActive ? "deactivate" : "activate"}
                        width={20}
                        height={20}
                        onClick={(event) => {
                            event.stopPropagation();
                        }}
                        className="me-4 cursor-pointer"
                    />

                    <Icon icon="copy" width={20} height={20} className="me-4 cursor-pointer"
                          onClick={(event) => {
                              event.stopPropagation();
                          }}
                    />
            </section>
        }
    }
];