import TableSearch from "../../../../../base/components/Table/tableSearch";
import React, { useState } from "react";
import Button from "../../../../../base/components/Button";
import { useLocationQuery } from "../../../../../base/hooks/useQueryString";
import { useHighlight } from "../../../../../base/hooks/useHighlight";
import { BUTTON_COLORS } from "../../../../../base/components/Button/appearance";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import Checkbox from "../../../../../base/components/Checkbox";
import { PROGRAM_STATUSES, PROGRAM_STATUSES_LABELS } from "../constants";
import { DateTime } from "luxon";
import { formatISODate, transformJsDateToIso } from "../../../../../base/helpers/date";
import { DateRangePicker } from "../../../../../base/components/DateRangePicker";
import joinClassNames from "../../../../../base/helpers/joinClassNames";
import Icon from "../../../../../base/components/Icon";
import classnames from "classnames";
import { PROGRAMS_GROUP_LINKS } from "../../config";
import { useNavigate } from "react-router-dom";

export const PROGRAMS_ACTIONS = {
    VIEW_RULES: 'viewRules',
    VIEW_NUTRIENT_GOAL: 'viewNutrientGoal',
    DEACTIVATE: 'deactivate',
    ACTIVATE: 'activate',
    COPY: 'copy',
    EDIT: 'edit',
    DELETE: 'delete'
};

export const VIEW_RULES = { id: 1, label: 'View rules', icon: 'list', type: PROGRAMS_ACTIONS.VIEW_RULES };
export const VIEW_NUTRIENT_GOAL = {
    id: 2,
    label: 'View nutritional goals',
    icon: 'userAttributes',
    type: PROGRAMS_ACTIONS.VIEW_NUTRIENT_GOAL
};

export const DEACTIVATE = {
    id: 3,
    label: 'Deactivate program',
    icon: 'deactivate',
    type: PROGRAMS_ACTIONS.DEACTIVATE
};
export const ACTIVATE = {
    id: 4,
    label: 'Activate program',
    icon: 'activate',
    type: PROGRAMS_ACTIONS.ACTIVATE
};

export const COPY = {
    id: 5,
    label: 'Copy program',
    icon: 'copy',
    type: PROGRAMS_ACTIONS.COPY
};

export const EDIT = {
    id: 6,
    label: 'Edit program',
    icon: 'edit',
    type: PROGRAMS_ACTIONS.EDIT
};

export const DELETE = {
    id: 7,
    label: 'Delete program',
    icon: 'trashIcon',
    className: "text-danger",
    type: PROGRAMS_ACTIONS.DELETE
};

export const SearchPlaceholder = () => (
    <>
        No lab results found.
    </>
);

export const NoProgramPlaceholder = () => (
    <div className="text-center">
        No programs for now.<br/>
        Click the “Create program” option to add a new one.
    </div>
);

export const TableFilterByStatus = ({ filterProvider }) => {
    const [isOpen, updateIsOpen] = useState(false);
    const currentValue = filterProvider?.getValue();
    const initialValue = currentValue ? (typeof currentValue === "object" ? currentValue : [currentValue]) : [];
    const [statuses, updateStatuses] = useState(initialValue ?? []);

    const submitFilters = () => {
        filterProvider.setValue(statuses);
        updateIsOpen(false);
    };

    const handleCheckboxChange = (id) => {
        updateStatuses(prevState => {
            return prevState.includes(id) ? prevState.filter(item => item !== id) : [...prevState, id];
        });
    };

    return (
        <section className="d-flex align-items-center">
            <Dropdown
                isOpen={isOpen}
                toggle={() => updateIsOpen(prevState => !prevState)}
                className="d-inline-block"
            >
                <DropdownToggle
                    className="text-nowrap btn btn-outline-primary d-flex align-items-center"
                    tag="button"
                >
                    <i className="bx bx-filter-alt me-2"/> Filter by status
                </DropdownToggle>

                <DropdownMenu className="filter-menu pb-1 px-1">

                    <section className="filter-options mx-1 custom-scrollbar">
                        {Object.values(PROGRAM_STATUSES).map((status, index) => {
                            return (
                                <Checkbox
                                    id={status}
                                    text={PROGRAM_STATUSES_LABELS[status]}
                                    value={statuses?.includes(status)}
                                    onChange={() => handleCheckboxChange(status)}
                                    key={index}
                                    className="my-2 ms-1"
                                />
                            );
                        })}
                    </section>

                    <section className="w-100 px-2 pb-2">
                        <Button
                            color={BUTTON_COLORS.primary}
                            onClick={submitFilters}
                            className="w-100"
                            disabled={!statuses.length}>
                            Apply
                        </Button>
                    </section>

                </DropdownMenu>
            </Dropdown>

            {!!filterProvider?.getValue() &&
                <Button
                    color={BUTTON_COLORS.transparent}
                    onClick={() => {
                        updateStatuses([]);
                        filterProvider?.setValue();
                    }}
                    className="text-danger no-border ms-2"
                >
                    Clear filter
                </Button>
            }
        </section>
    );
};

export const ThreeDotDropdown = ({ isOpen, updateIsOpen, options, onSelect }) => {
    return (
        <Dropdown isOpen={isOpen} toggle={() => updateIsOpen(prevState => !prevState)}
                  className="d-inline-block filter-dropdown ms-2">
            <DropdownToggle
                className={joinClassNames('btn btn-transparent no-border no-outline w-fit-content p-0', isOpen && '')}
                tag="button">
                <Icon icon="threeDots" className=""/>
            </DropdownToggle>
            <DropdownMenu end className="three-dot-dropdown w-150 ">
                {options.map(({ label, icon, type, id, disabled, className }) => {
                    return (
                        <DropdownItem disabled={disabled === true}
                                      className={classnames("three-dot-dropdown__item w-100", className || '')} key={id}
                                      onClick={() => onSelect(type)}>
                            <Icon icon={icon} className="me-2 three-dot-dropdown__icon"/>
                            <p className="mb-0">{label}</p>
                        </DropdownItem>
                    );
                })}
            </DropdownMenu>
        </Dropdown>
    );
};


export const TableHeader = ({ searchProvider, filterProvider, dateRangeProviders }) => {
    const [[createdAtStartDate, createdAtDateEndDate], updateCreatedAt] = useState(dateRangeProviders?.createdAt?.getValue());
    const navigate = useNavigate();
    const goToCreate = () => {
        navigate(PROGRAMS_GROUP_LINKS.CREATE_EDIT)
    }

    return (
        <section className="d-flex w-100 pt-1 my-3 align-items-center justify-content-between">
            <div className="d-flex align-items-center gap-4">
                <TableSearch
                    className="biomarkers-search"
                    search={searchProvider?.getValue()}
                    onSearch={searchProvider.setValue}
                    placeholder="Search program"
                />

                <TableFilterByStatus filterProvider={filterProvider}/>

                <label className="d-flex align-items-center gap-2">
                    <span className="mt-2 text-nowrap">Filter by date:</span>
                    <DateRangePicker
                        className="me-4"
                        startDate={createdAtStartDate}
                        endDate={createdAtDateEndDate}
                        onChange={([startDate, endDate]) => {
                            if (!startDate && !endDate) {
                                updateCreatedAt([null, null]);
                                dateRangeProviders.createdAt.setValue({});
                                return;
                            }
                            const startDateFormatted = DateTime.fromJSDate(startDate).startOf('day');
                            updateCreatedAt([startDateFormatted.toJSDate(), endDate]);

                            if (endDate) {
                                const values = {
                                    createdAtStartDate: transformJsDateToIso(startDate.toISOString(), true),
                                    createdAtDateEndDate: DateTime.fromJSDate(endDate).plus({ days: 1 }).toUTC().endOf('day').toISO()
                                };
                                dateRangeProviders.createdAt.setValue(values);
                            }
                        }}
                    />
                </label>
            </div>


            <Button color={BUTTON_COLORS.primary} className="d-flex align-items-center" onClick={goToCreate}>
                <i className="bx bx-plus me-2"/>
                Create program
            </Button>
        </section>
    );
};

export const columns = [
    {
        Header: "#",
        width: 35,
        Cell: (cellProps) => {
            const { params: { offset = 0 } } = useLocationQuery();
            return <label className="mb-0">{cellProps.row.index + 1 + (offset ?? 0)}</label>;
        }
    },
    {
        Header: "Name",
        accessor: "fullName",
        width: 410,
        canSort: true,
        Cell: ({ value }) => {
            const { params } = useLocationQuery();
            const { decorateText } = useHighlight(params.search);

            return (
                <section className="w-fit-content d-flex align-items-center">
                    <div {...decorateText(value)} className="text-truncate mb-0" style={{ maxWidth: "33vw" }}/>
                </section>
            );
        }
    },
    {
        Header: "Author",
        accessor: "author",
        width: 410,
        canSort: true,
        Cell: ({ value }) => <span>{value}</span>
    },
    {
        Header: "Created on",
        accessor: "createdAt",
        width: 410,
        canSort: true,
        Cell: ({ value }) => <section className="w-fit-content d-flex align-items-center">
            {formatISODate(value)}
        </section>
    },
    {
        Header: "Active",
        accessor: "isActive",
        width: 410,
        canSort: true,
        Cell: ({ value }) => <div className={joinClassNames(
            value ? "active-pill" : "inactivated-pill",
            "w-fit-content"
        )}>
            {value ? "Active" : "Inactive"}
        </div>
    },
    {
        Header: "Actions",
        Cell: ({
                   row: {
                       original: { id, status, ...rest }
                   },
                   actions
               }) => {
            const [isOpen, updateIsOpen] = useState(false);

            const ACTION_OPTION = [
                VIEW_RULES,
                VIEW_NUTRIENT_GOAL,
                status === PROGRAM_STATUSES.ACTIVE ? DEACTIVATE : ACTIVATE,
                COPY,
                EDIT,
                DELETE
            ];

            const handleSelect = (type) => {
                actions?.[type] && actions[type]({ id, status, ...rest })
            };

            return <div>
                <ThreeDotDropdown
                    isOpen={isOpen}
                    updateIsOpen={updateIsOpen}
                    options={ACTION_OPTION}
                    onSelect={handleSelect}
                />
            </div>;
        }
    }
];