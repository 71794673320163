import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";

import Table from "../../../../../base/components/Table";
import { DEFAULT_DEBOUNCE_DELAY, DEFAULT_TABLE_LIMIT } from "../../../../../base/constants/shared";
import {
    usePaginationProvider,
    useLocationSource, useSearchProvider, useFilterProvider, useDateRangeProvider, useSortProvider
} from "../../../../../base/components/Table/hooks";
import { useDebounce } from "../../../../../base/hooks/useDebounce";
import { SearchPlaceholder, TableHeader, columns, NoProgramPlaceholder } from "./components";
import ToasterService from "../../../../../services/ToastService";
import { useService } from "../../../../../base/hooks/useService";
import ProgramsService from "../../../../../services/ProgramsService";
import { useLoading } from "../../../../../base/hooks/useLoading";
import { useQueryString } from "../../../../../base/hooks/useQueryString";
import ConfirmPopup from "../../../../../base/components/ConfirmPopup";

export default function PredefinedList() {
    /**
     * @type {ProgramsService}
     */
    const programsService = useService(ProgramsService);
    /**
     * @type {ToasterService}
     */
    const toastService = useService(ToasterService);

    const [isLoading, { registerPromise }] = useLoading(true);
    const { search: locationSearch, pathname } = useLocation();
    const {
        params: {
            limit = DEFAULT_TABLE_LIMIT,
            offset = 0,
            search,
            fullName,
            author,
            isActive,
            createdAt
        }
    } = useQueryString(locationSearch);

    const [programs, updatePrograms] = useState([]);
    const [programsPagination, updateProgramsPagination] = useState({});
    const [showDeletePopup, updateShowDeletePopup] = useState(null);

    const locationSource = useLocationSource();

    const paginationProvider = usePaginationProvider({
        source: locationSource,
        alias: "offset",
        scope: "",
        fallback: 0
    });

    const [debouncedSearch] = useDebounce(search, DEFAULT_DEBOUNCE_DELAY, () => paginationProvider.setValue(0));

    const generateFilterProvidersParams = (names = []) => {
        return {
            source: locationSource,
            alias: names,
            scope: "",
            onApplyClearScope: ["offset"]
        };
    };

    const searchProvider = useSearchProvider({
        source: locationSource,
        scope: "",
        alias: 'search',
        onApplyClearScope: [""]
    });

    const filterProvider = useFilterProvider({
        source: locationSource,
        scope: "",
        alias: 'status',
        onApplyClearScope: ["offset"]
    });

    const sortKeys = ["fullName", "author", "createdAt", "isActive"];
    const getSortScope = (key) => [...sortKeys.filter(item => item !== key), "offset"];

    const nameSortProvider = useSortProvider({
        source: locationSource,
        alias: "fullName",
        scope: "",
        onApplyClearScope: getSortScope("fullName")
    });

    const authorSortProvider = useSortProvider({
        source: locationSource,
        alias: "author",
        scope: "",
        onApplyClearScope: getSortScope("author")
    });

    const createdAtSortProvider = useSortProvider({
        source: locationSource,
        alias: "createdAt",
        scope: "",
        onApplyClearScope: getSortScope("createdAt")
    });

    const statusSortProvider = useSortProvider({
        source: locationSource,
        alias: "isActive",
        scope: "",
        onApplyClearScope: getSortScope("isActive")
    });

    const dateRangeProviders = {
        createdAt: useDateRangeProvider(generateFilterProvidersParams(["createdAtStartDate", "createdAtEndDate"]))
    };

    const getPrograms = useCallback(() => {
        const [orderBy, orderType] = Object.entries({
            fullName,
            author,
            isActive,
            createdAt
        }).find(([_, value]) => value) || [];

        registerPromise(programsService.getPrograms({
            limit,
            offset,
            orderBy,
            orderType,
            query: search,
        })
            .then(({ data, pagination }) => {
                updatePrograms(data);
                updateProgramsPagination(pagination);
            }));
    }, [limit, offset, fullName, author, isActive, createdAt, search]);

    const handleClickDeleteProgram = (program) => {
      updateShowDeletePopup(program)
    }

    const deleteProgram = (program) => {
      registerPromise(programsService.deleteProgram(program.id))
          .then(() => toastService.success("The program has been successfully deleted"))
    }

    useEffect(() => {
        getPrograms();
    }, [getPrograms]);

    return (
        <>
          <Table
            columns={programs.length ? columns : []}
            data={programs}
            loading={isLoading}
            HeaderComponent={TableHeader}
            totalCount={programsPagination.totalCount}
            limit={DEFAULT_TABLE_LIMIT}
            offset={offset}
            paginationProvider={paginationProvider}
            filterProvider={filterProvider}
            dateRangeProviders={dateRangeProviders}
            searchProvider={searchProvider}
            commonPlaceholder={<NoProgramPlaceholder/>}
            placeholderForSearch={<SearchPlaceholder/>}
            actions={{}}
            sortProviders={{
                fullName: nameSortProvider,
                author: authorSortProvider,
                status: statusSortProvider,
                createdAt: createdAtSortProvider
            }}
        />
          {showDeletePopup &&
              <ConfirmPopup
                  isOpen={showDeletePopup}
                  updateIsOpen={updateShowDeletePopup}
                  onSubmit={() => {
                    deleteProgram(showDeletePopup)
                    updateShowDeletePopup(null)
                  }}
                  title="Delete program"
                  description="Are you sure you want to delete the chosen program?"
                  submitBtnText="Delete"
                  className="upload-manually__popup"
              />
          }
          </>
    );
}
