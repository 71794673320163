import React from "react";
import { storyblokInit, apiPlugin } from "@storyblok/react";
import PropTypes from "prop-types";
import Page from "./components/Page";
import Button from "./components/Button";
import GridSection from "./components/GridSection";
import TextSection from "./components/TextSection";
import ImageSection from "./components/ImageSection";
import RichText from "./components/RichText";
import VideoSection from "./components/VideoSection";
import YoutubeVideoSection from "./components/YoutubeVideoSection"
import GoogleDriveVideo from "./components/GoogleDriveVideo";
import Header from "./components/Header";
import GridCard from "./components/GridCard";
import Carousel from "./components/Carousel";
import VimeoVideoSection from "./components/VimeoVideoSection";
import Banner from "./components/Banner"
import Attributes from "./components/Attributes";
import Tags from "./components/Tags";

import "./index.scss"
import Reaction from "./components/Reaction";
import ShareBlock from "./components/ShareBlock";
import RelatedArticles from "./components/RelatedArticles";
import Quote from "./components/Quote";
import Exercise from "./components/Exercise";
import FitnessTitle from "./components/FitnessTitle";
import ExercisesWrapper from "./components/ExercisesWrapper";

const Storyblock = ({ children }) => {
    storyblokInit({
        accessToken: process.env.REACT_APP_STORYBOOK_TOKEN,
        use: [apiPlugin],
        components: {
            "default-page": Page,
            "grid-card": GridCard,
            "grid": GridSection,
            "text-section": TextSection,
            "image-section": ImageSection,
            "rich-text": RichText,
            "root": Page,
            "youtube-video": YoutubeVideoSection,
            "video-section": VideoSection,
            "google-drive-video": GoogleDriveVideo,
            "header": Header,
            "button": Button,
            "carousel": Carousel,
            "vimeo-section": VimeoVideoSection,
            "attributes": Attributes,
            "tags": Tags,
            "banner": Banner,
            "reaction": Reaction,
            "share-block": ShareBlock,
            "related-articles": RelatedArticles,
            "quote": Quote,
            "exercise": Exercise,
            "exercises-wrapper": ExercisesWrapper,
            "fitness-title": FitnessTitle,
        }
    });

    return <>{children}</>
}

export default Storyblock

Storyblock.propTypes = {
    children: PropTypes.any
};