import React, { useCallback, useEffect, useMemo, useState } from 'react';

import PropTypes from 'prop-types';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Collapse } from 'reactstrap';
import SimpleBar from "simplebar-react";
import { useTranslate } from '../../hooks/useTranslate';
import Icon from "../Icon";
import joinClassNames from "../../helpers/joinClassNames";
import TemplateLink from "./TemplateLink";
import { useService } from "../../hooks/useService";
import TemplateService from "../../../services/TemplateService";
import { APP_GROUP_LINKS } from "../../../groups/app/config";
import { useTemplates, useUpdateTemplates } from "../../context/templates";

const ACTIVE_LINK = 'waves-effect active mm-active';
const ARROW = 'has-arrow';
const CONDENSED = 'condensed';

export default function generateSidebarLinks(linksArray, sidebarType) {
  /**
   * @type {TemplateService}
   */
  const templateService = useService(TemplateService);

  const location = useLocation();
  const currentLocation = useMemo(() => location.pathname, [location]);
  const navigate = useNavigate();

  const isCondensed = sidebarType === CONDENSED;

  const [translate] = useTranslate();

  const [activeLink, setActiveLink] = useState(currentLocation);
  const [templates, updateTemplates] = useState([]);

  const { shouldUpdate } = useTemplates();
  const updateTemplatesOnSidebar = useUpdateTemplates()

  const [isOpenTemplateCollapse, updateIsOpenTemplateCollapse] = useState(true);

  function getLinkClassName(link, arrow = '') {
    if(currentLocation.includes(link)) {
      return `${ACTIVE_LINK} ${arrow}`;
    }
    return `waves-effect ${arrow}`;
  }

  function isOpenedCollapse(link) {
    if(link?.length > activeLink?.length) {
      return;
    }
    return activeLink?.startsWith(link)
  }

  const toggleCollapse = (link, parentLink) => {
    setActiveLink(prevState => {
      return prevState === link ? parentLink : link;
    });
  }

  function getLinksFloor(children, parentLink) {
    return children.map(child => {

      const hasChilds = !!child.children?.length;

      return (<li key={child.linkTo}>
        <a
          className={joinClassNames(hasChilds ? getLinkClassName(child.linkTo, ARROW) : getLinkClassName(child.linkTo), "d-flex align-items-center w-100")}
          onClick={(event) => {
            if(hasChilds) {
              toggleCollapse(child.linkTo, parentLink)
            } else {
              setActiveLink(child.linkTo)
              navigate(child.linkTo)
            }
            event.stopPropagation()
          }}
        >
          {child.icon &&
            <Icon
              icon={child.icon}
              className={joinClassNames("link-icon", activeLink?.includes(child.linkTo) && "active")}
            />}
          <span>{translate(child.label)}</span>
        </a>
        {(hasChilds && (!isCondensed ? <Collapse isOpen={isOpenedCollapse(child.linkTo)}>
          <ul className="sub-menu">
            {getLinksFloor(child.children, child.linkTo)}
          </ul>
        </Collapse> : <ul className="sub-menu">
          {getLinksFloor(child.children, child.linkTo)}
        </ul>))}
      </li>)
    })
  }

  const getTemplates = useCallback(() => {
    templateService.getTemplateList({
      limit: 100,
      offset: 0,
      isFavourite: true,
    }).then(({ data }) => {
        updateTemplates(data)
      })
  }, [templateService]);

  useEffect(() => {
    getTemplates();
  }, [getTemplates]);

  useEffect(() => {
    if(!shouldUpdate) return;
    updateTemplatesOnSidebar({ shouldUpdate: false })
    getTemplates()
  }, [shouldUpdate]);

  const isActiveCustomerResults = useMemo(() => {
    return currentLocation.includes(APP_GROUP_LINKS.CUSTOMER_RESULTS);
  }, [currentLocation])

  const setActiveLinkToCustomerResults = () => {
    setActiveLink(APP_GROUP_LINKS.CUSTOMER_RESULTS)
  };

  const linksTop = linksArray.filter(({bottomLink}) => !bottomLink)
  const linksBottom = linksArray.filter(({bottomLink}) => bottomLink)


  return (
    <SimpleBar className="h-100">
      <div id="sidebar-menu">
        <ul className="metismenu list-unstyled custom-sidebar-menu">
          {getLinksFloor(linksTop)}
          <li>
            <Link
              to={APP_GROUP_LINKS.CUSTOMER_RESULTS}
              className={joinClassNames(
                isActiveCustomerResults && "text-white",
                "d-flex align-items-center w-100",
                isOpenTemplateCollapse && "active-arrow"
              )}
              onClick={() => {
                toggleCollapse(APP_GROUP_LINKS.CUSTOMER_RESULTS)
              }}
            >
              <Icon
                icon="customerResults"
                className={joinClassNames("link-icon", isActiveCustomerResults && "active")}
              />
              <div className="w-100">{translate("SIDEBAR.CUSTOMER_RESULTS")}</div>
              <div
                className={joinClassNames("has-arrow",)}
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  updateIsOpenTemplateCollapse(prevState => !prevState);
                }}
              />
            </Link>
          </li>

          {(!!templates.length &&
            <Collapse isOpen={isOpenTemplateCollapse}>
              <label className="sidebar-templates__title">Favorite templates:</label>
              <ul className="sub-menu sidebar-templates custom-scrollbar me-2">
                {templates.map((template) => {
                  return (
                    <li key={template?.id?.toString()}>
                      <TemplateLink
                        template={template}
                        setActiveLinkToCustomerResults={setActiveLinkToCustomerResults}
                      />
                    </li>
                  )
                })}
              </ul>
            </Collapse>
          )}
          {getLinksFloor(linksBottom)}

        </ul>
      </div>
    </SimpleBar>
  )
}

generateSidebarLinks.propTypes = {
  linksArray: PropTypes.array,
}
