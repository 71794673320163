import React, { useCallback, useEffect, useMemo, useState } from 'react'
import BaseLayoutWithCard from "../../../../base/components/BaseLayoutWithCard";
import Table from "../../../../base/components/Table";
import { EMPTY_ARRAY, CSV_FILE_MASK } from "../../../../base/constants/shared";
import { useService } from "../../../../base/hooks/useService";
import { useLocation } from "react-router-dom";
import { parseQuery } from "../../../../base/hooks/useQueryString";
import { useLoading } from "../../../../base/hooks/useLoading";
import {
  parseSorting, useDateRangeProvider,
  useFilterProvider,
  useLocationSource,
  usePaginationProvider,
  useSearchProvider,
  useSortProvider
} from "../../../../base/components/Table/hooks";
import UserResultsService from "../../../../services/UserResults";
import { SearchPlaceholder, TableHeader, columns, DEFAULT_STATUSES, STATUS_IDS, STATUSES } from "./components";
import ConfirmPopup, { ConfirmPopupWithReason } from "../../../../base/components/ConfirmPopup";
import FilePopup from "./FilePopup";
import FileUpload from "./FileUpload";
import CsvViewer from "./CsvViewer";
import ResultsPopupBlood from "./ResultsPopupBlood";
import { useUpdateNotifications } from "../../../../base/context/notifications";
import { transformJsDateToIso } from "../../../../base/helpers/date";
import ToasterService from "../../../../services/ToastService";
import { CUSTOMER_RESULTS_CATEGORIES } from "../constants";
import ResultsPopupFoodIntolerance from "./ResultsPopupFoodIntolerance";

const breadcrumbs = {
  title: "Customers result",
  breadcrumbItems: [],
}

const LIMIT_OPTIONS = [
  { label: "15", value: 15 },
  { label: "30", value: 30 },
  { label: "50", value: 50 },
  { label: "100", value: 100 },
]

const resultPopupComponents = {
  [CUSTOMER_RESULTS_CATEGORIES.BLOOD_CATEGORY]: ResultsPopupBlood,
  [CUSTOMER_RESULTS_CATEGORIES.FOOD_INTOLERANCE_CATEGORY]: ResultsPopupFoodIntolerance,
}

export default function ResultsList() {
  /**
   * @type {UserResultsService}
   */
  const userResultsService = useService(UserResultsService);
  /**
   * @type {ToasterService}
   */
  const toastService = useService(ToasterService);

  const updateNotifications = useUpdateNotifications();

  const [userResults, updateUserResults] = useState([]);
  const [userResultsPagination, updateUserResultsPagination] = useState({ totalCount: 0 });

  const [userResultDetails, updateUserResultDetails] = useState({})
  const [isOpenDetailsPopup, updateIsOpenDetailsPopup] = useState(false);
  const [isCsvPopupOpen, setIsCsvPopupOpen] = useState(false);
  const [isViewCsvOpen, setIsViewCsvOpen] = useState(false);
  const [isOpenResultsPopup, updateIsOpenResultsPopup] = useState(false);
  const [isOpenCancellationReasonPopup, updateIsOpenCancellationReasonPopup] = useState(false);

  const [showConfirmVerifyPopup, updateShowConfirmVerifyPopup] = useState(false);
  const [showCancelVerifyPopup, updateShowCancelVerifyPopup] = useState(false);
  const [showConfirmReprocessPopup, updateShowReprocessPopup] = useState(false);

  const [cancellationReason, updateCancellationReason] = useState();

  const { search: locationSearch } = useLocation();
  const {
    offset = 0,
    search,
    name,
    activatedAt,
    resultAt,
    sampleAt,
    dateOfBirthStartDate,
    dateOfBirthEndDate,
    activatedAtStartDate,
    activatedAtEndDate,
    sampleAtStartDate,
    sampleAtEndDate,
    resultAtStartDate,
    resultAtEndDate,
    labReceivedAtStartDate,
    labReceivedAtEndDate,
    activatedAtFilterType,
    sampleAtFilterType,
    resultAtFilterType,
    labReceivedAtFilterType,
    status = EMPTY_ARRAY,
  } = parseQuery(locationSearch);
  const [isLoading, { registerPromise }] = useLoading(true);

  const locationSource = useLocationSource();

  const limitProvider = usePaginationProvider({
    source: locationSource,
    alias: "limit",
    scope: "",
    fallback: 15,
    onApplyClearScope: ["offset"]
  });

  const limit = limitProvider.getValue();

  const paginationProvider = usePaginationProvider({
    source: locationSource,
    alias: "offset",
    scope: "",
    fallback: 0
  });

  const generateSortProviderParams = (name) => {
    const allParams = ["name", "activatedAt", "resultAt", "labReceivedAt"];
    return {
      source: locationSource,
      alias: name,
      scope: "",
      onApplyClearScope: allParams.filter(paramName => paramName !== name)
    }
  }

  const generateFilterProvidersParams = (names = []) => {
    return {
      source: locationSource,
      alias: names,
      scope: "",
      onApplyClearScope: ["offset"],
    }
  }

  const nameSortProvider = useSortProvider(generateSortProviderParams("name"));
  const activatedAtSortProvider = useSortProvider(generateSortProviderParams("activatedAt"));
  const resultAtProvider = useSortProvider(generateSortProviderParams("resultAt"));
  const sampleAtSortProvider = useSortProvider(generateSortProviderParams("sampleAt"));

  const searchProvider = useSearchProvider({
    source: locationSource,
    scope: "",
    alias: 'search',
    onApplyClearScope: ["offset"]
  })

  const filterProvider = useFilterProvider({
    source: locationSource,
    scope: "",
    alias: 'status',
    onApplyClearScope: ["offset"]
  })

  const getUsersResults = useCallback(() => {
    const sortObject = parseSorting({ name, activatedAt, resultAt, sampleAt });
    registerPromise(userResultsService.getUserResults({
        limit,
        offset,
        search: search || undefined,
        status,
        dateOfBirthStartDate: transformJsDateToIso(dateOfBirthStartDate, true),
        dateOfBirthEndDate: transformJsDateToIso(dateOfBirthEndDate, true, true),
        activatedAtStartDate: transformJsDateToIso(activatedAtStartDate, !activatedAtFilterType),
        activatedAtEndDate: transformJsDateToIso(activatedAtEndDate, !activatedAtFilterType, !activatedAtFilterType),
        sampleAtStartDate: transformJsDateToIso(sampleAtStartDate, !sampleAtFilterType),
        sampleAtEndDate: transformJsDateToIso(sampleAtEndDate, !sampleAtFilterType, !sampleAtFilterType),
        resultAtStartDate: transformJsDateToIso(resultAtStartDate,  !resultAtFilterType),
        resultAtEndDate: transformJsDateToIso(resultAtEndDate, !resultAtFilterType, !resultAtFilterType),
        labReceivedAtStartDate: transformJsDateToIso(labReceivedAtStartDate, !labReceivedAtFilterType),
        labReceivedAtEndDate: transformJsDateToIso(labReceivedAtEndDate, !labReceivedAtFilterType, !labReceivedAtFilterType),
        ...sortObject,
      })
        .then(({ data, pagination }) => {
          updateUserResults(data);
          updateUserResultsPagination(pagination)
        })
    )
  }, [
    offset,
    limit,
    search,
    name,
    activatedAt,
    resultAt,
    sampleAt,
    dateOfBirthStartDate,
    dateOfBirthEndDate,
    activatedAtStartDate,
    activatedAtEndDate,
    sampleAtStartDate,
    sampleAtEndDate,
    resultAtStartDate,
    resultAtEndDate,
    labReceivedAtStartDate,
    labReceivedAtEndDate,
    activatedAtFilterType,
    sampleAtFilterType,
    resultAtFilterType,
    labReceivedAtFilterType,
    status.toString(),
  ]);

  const changeUserResultStatus = (id, status, callback = () => {}, additionalValues = {}) => {
    userResultsService.changeResultStatus(id, { status, ...additionalValues })
      .then(() => {
        return getUsersResults()
      })
      .then(() => toastService.success(`Kit status updated to ${STATUSES[status]}`))
      .finally(() => callback())
  }

  const handleMarkAsReceivedByLab = (id) => {
    return userResultsService.receivedByLab(id)
        .then(() => {
          getUsersResults()
          toastService.success("Kit status updated to In Progress")
        })
  }

  const reprocessHL7 = (id) => {
    userResultsService.reprocessHL7(id).then(() => {
      getUsersResults()
    })
  }

  useEffect(() => {
    getUsersResults();
  }, [getUsersResults])

  const sortProviders = useMemo(() => ({
    name: nameSortProvider,
    activatedAt: activatedAtSortProvider,
    resultAt: resultAtProvider,
    sampleAt: sampleAtSortProvider,
  }), [nameSortProvider, activatedAtSortProvider, resultAtProvider, sampleAtSortProvider]);

  const ResultPopup = useMemo( () => {
    // -- if sampleCode is not exist -> it's manually uploaded PDF Results --
    if (typeof isOpenResultsPopup == "object" && !isOpenResultsPopup?.sampleCode) {
      isOpenResultsPopup.category = CUSTOMER_RESULTS_CATEGORIES.BLOOD_CATEGORY;
    }
    return resultPopupComponents[isOpenResultsPopup.category]
  }, [isOpenResultsPopup])

  const dateRangeProviders = {
    dateOfBirth: useDateRangeProvider(generateFilterProvidersParams(["dateOfBirthStartDate", "dateOfBirthEndDate"])),
    activatedAt: useDateRangeProvider(generateFilterProvidersParams(["activatedAtStartDate", "activatedAtEndDate", "activatedAtFilterType"])),
    sampleAt: useDateRangeProvider(generateFilterProvidersParams(["sampleAtStartDate", "sampleAtEndDate", "sampleAtFilterType"])),
    labReceivedAt: useDateRangeProvider(generateFilterProvidersParams(["labReceivedAtStartDate", "labReceivedAtEndDate", "labReceivedAtFilterType"])),
    resultAt: useDateRangeProvider(generateFilterProvidersParams(["resultAtStartDate", "resultAtEndDate", "resultAtFilterType"])),
  }

  useEffect(() => {
    if(locationSearch.length) return;
    filterProvider.setValue(DEFAULT_STATUSES)
  }, []);

  return (
    <BaseLayoutWithCard breadcrumbs={breadcrumbs} cardClassName="pt-0">
      <Table
        columns={columns}
        data={userResults}
        isAddNewBiomarker
        loading={isLoading}
        HeaderComponent={TableHeader}
        totalCount={userResultsPagination.totalCount}
        limit={limitProvider.getValue()}
        offset={offset}
        sortProviders={sortProviders}
        paginationProvider={paginationProvider}
        actions={{
          markAsCancelled: (id) => updateShowCancelVerifyPopup(id),
          markAsVerified: (id) => updateShowConfirmVerifyPopup(id),
          viewFiles: (row) => {
            updateUserResultDetails(row)
            updateIsOpenDetailsPopup(true);
          },
          viewResults: (row) => updateIsOpenResultsPopup(row),
          reprocessHL7: (id) => updateShowReprocessPopup(id),
          viewCancellationReason: (reason) => {
            updateCancellationReason(reason);
            updateIsOpenCancellationReasonPopup(true);
          },
          markAsReceivedByLab: handleMarkAsReceivedByLab,
          uploadCSVFile: (row) => { setIsCsvPopupOpen(row); setIsCsvPopupCategory(row.category)},
          viewCSVFile: (row) => { setIsViewCsvOpen(row) },
          reprocessCSVFile: (row) => { setIsCsvPopupOpen(row) },
          downloadFile: (url) => url ? window.open(url, '_blank').focus() : undefined,
        }}
        searchProvider={searchProvider}
        commonPlaceholder="No customer results for now."
        placeholderForSearch={<SearchPlaceholder/>}
        filterProvider={filterProvider}
        dateRangeProviders={dateRangeProviders}
        hasActiveFilters={!!filterProvider.getValue()?.length || Object.values(dateRangeProviders || {}).some(provider => provider.getValue().some(value => !!value))}
        limitProvider={limitProvider}
        limitOptions={LIMIT_OPTIONS}
        isLimitEditable
      />

      {!!showConfirmVerifyPopup &&
        <ConfirmPopup
          isOpen={!!showConfirmVerifyPopup}
          updateIsOpen={updateShowConfirmVerifyPopup}
          onSubmit={() => changeUserResultStatus(showConfirmVerifyPopup, STATUS_IDS.verified, () => updateNotifications({ shouldUpdate: true }))}
          title="Confirmation"
          description="Are you sure you want to mark the result as verified?"
          submitBtnText="Confirm"
          className=""
        />
      }

      {!!showCancelVerifyPopup &&
        <ConfirmPopupWithReason
          isOpen={!!showCancelVerifyPopup}
          updateIsOpen={updateShowCancelVerifyPopup}
          onSubmit={(values) => changeUserResultStatus(showCancelVerifyPopup, STATUS_IDS.cancelled, () => {}, values)}
          title="Confirmation"
          description="Are you sure you want to mark the result as canceled?"
          submitBtnText="Confirm"
          className=""
        />
      }

      {!!showConfirmReprocessPopup &&
        <ConfirmPopup
          isOpen={!!showConfirmReprocessPopup}
          updateIsOpen={updateShowReprocessPopup}
          onSubmit={() => reprocessHL7(showConfirmReprocessPopup)}
          title="Confirmation"
          description="Are you sure you want to reprocess HL7 file again?"
          submitBtnText="Confirm"
          className=""
        />
      }

      {!!isOpenDetailsPopup &&
        <FilePopup
          isOpen={isOpenDetailsPopup}
          updateIsOpen={updateIsOpenDetailsPopup}
          details={userResultDetails}
          afterOnClose={updateUserResultDetails}
        />
      }

      {!!isCsvPopupOpen &&
        <FileUpload
          testObjectId={isCsvPopupOpen.id}
          isOpen={isCsvPopupOpen !== false}
          updateIsOpen={setIsCsvPopupOpen}
          title="Upload CSV"
          description="Drag & drop your DNA file or click to upload."
          submitBtnText="Confirm"
          fileMask={CSV_FILE_MASK}
          afterUpload={getUsersResults}
        />
      }

      {!!isViewCsvOpen &&
        <CsvViewer
          isOpen={isViewCsvOpen !== false}
          updateIsOpen={setIsViewCsvOpen}
          details={isViewCsvOpen}
          afterOnClose={setIsViewCsvOpen}
        />
      }

      {!!isOpenResultsPopup && !!ResultPopup &&
        <ResultPopup
          isOpen={!!isOpenResultsPopup}
          updateIsOpen={updateIsOpenResultsPopup}
          row={isOpenResultsPopup}
        />
      }

      {!!isOpenCancellationReasonPopup &&
        <ConfirmPopup
          isOpen={!!isOpenCancellationReasonPopup}
          updateIsOpen={updateIsOpenCancellationReasonPopup}
          onSubmit={() => updateIsOpenCancellationReasonPopup(false)}
          title="Reason of cancellation"
          description={cancellationReason || "-"}
          submitBtnText="Ok"
          className=""
          showCancelButton = {false}
          submitButtonClassName="px-4"
        />
      }
    </BaseLayoutWithCard>
  )
}
