export const APP_GROUP_ROUTES = {
    BASE: '/app/*',
    DASHBOARD: `/dashboard/*`,
    BIOMARKERS: `/biomarkers/*`,
    FOOD_INTOLERANCE: `/food-intolerance/*`,
    RECOMMENDATIONS: `/recommendations/*`,
    CUSTOMER_MANAGEMENT: `/customer-management/*`,
    CUSTOMER_RESULTS: `/customer-results/*`,
    CONTENT_PLAYGROUND: '/content-playground/*',
    FOOD_AND_DIET: '/food-and-diet/*',
    PROGRAMS: '/programs/*'
};

export const APP_GROUP_LINKS = {
    BASE: '/app',
    DASHBOARD: `/app/dashboard`,
    BIOMARKERS: `/app/biomarkers`,
    RECOMMENDATIONS: `/app/recommendations`,
    CUSTOMER_MANAGEMENT: `/app/customer-management`,
    CUSTOMER_RESULTS: `/app/customer-results`,
    CONTENT_PLAYGROUND: '/app/content-playground',
    PROGRAMS: '/app/programs'
};
