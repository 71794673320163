import yup from "../../../../../validation/yup";
import {
    ALLOWED_EXTENTIONS_FILE,
    MAX_PROGRAM_NAME_LENGTH,
    MIN_STRING_LENGTH,
    ERROR_ALLOWED_TYPE_MESSAGE,
    ENERGY_EQUATION_LABEL, ENERGY_EQUATION
} from "./constants";
import {
    ERROR_SIZE_MESSAGE,
    isAllowedExtension,
    MAX_FILE_SIZE
} from "../../../../../base/components/Dropzone";

export const initialValues = {
    fullName: "",
    author: "",
    energyEquation: null,
    isActive: false,
    restriction: [],
    shortDescription: '',
    longDescription: ''
}

export const validationSchema = yup.object().shape({
    fullName: yup.string()
        .trim()
        .min(MIN_STRING_LENGTH)
        .max(MAX_PROGRAM_NAME_LENGTH)
        .required(),

    author: yup.string()
        .trim()
        .min(MIN_STRING_LENGTH)
        .max(MAX_PROGRAM_NAME_LENGTH)
        .required(),

    energyEquation: yup.number().required()
});

export const validateFile = (file) => {
    if (file?.size > MAX_FILE_SIZE) {
        return ERROR_SIZE_MESSAGE;
    }

    if (!isAllowedExtension(file, ALLOWED_EXTENTIONS_FILE)) {
        return ERROR_ALLOWED_TYPE_MESSAGE;
    }

    return null;
};

export const energyEquationOptions = Object.values(ENERGY_EQUATION).map((value) => ({
    value,
    id: value,
    label: ENERGY_EQUATION_LABEL[value]
}))
