export const PROGRAM_LIST_TABS = {
    PROGRAMS: "My programs",
    PREDEFINED: "Predefined"
}

export const PROGRAM_STATUSES = {
    ACTIVE: 0,
    INACTIVE: 1
}

export const PROGRAM_STATUSES_LABELS = {
    [PROGRAM_STATUSES.ACTIVE]: 'Active',
    [PROGRAM_STATUSES.INACTIVE]: 'Inactive'
}